import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterRoster",
})
export class FilterRosterPipe implements PipeTransform {
  transform(
    listShifts,
    selectedSearchesByVenue,
    selectedSearchesByClient,
    selectedSearchesBySupplier,
    selectedSearchesByResource,
    selectedSearchesByStatus
  ) {
    // Filtering by Venue
    // if (selectedSearchesByVenue && selectedSearchesByVenue.length) {
    //   return listShifts.filter((shift) => {
    //     return selectedSearchesByVenue.includes(shift.venue.name);
    //   });
    // }
    // // Filtering by Client
    // if (selectedSearchesByClient && selectedSearchesByClient.length) {
    //   return listShifts.filter((shift) => {
    //     return selectedSearchesByClient.includes(shift.client_uuid);
    //   });
    // }
    // Filtering by Supplier
    if (selectedSearchesBySupplier && selectedSearchesBySupplier.length) {
      return listShifts.filter((shift) => {
        return shift.tasks.some((task) =>
          selectedSearchesBySupplier.includes(task.supplier.name)
        );
      });
    }
    // Filtering by Resource
    if (selectedSearchesByResource && selectedSearchesByResource.length) {
      return listShifts.filter((shift) => {
        return shift.tasks.some((task) =>
          selectedSearchesByResource.includes(task.resource.name)
        );
      });
    }
    // Filtering by Status
    if (selectedSearchesByStatus && selectedSearchesByStatus.length) {
      let uniqShifts = [];
      let shifts = [];
      [...listShifts].map((shift) => {
        if (!uniqShifts.includes(shift.uuid)) {
          if (
            selectedSearchesByStatus.includes("completed") &&
            shift.tasks.some((t) => t.timesheet?.signoff?.signature)
          ) {
            uniqShifts.push(shift.uuid);
            shifts.push(shift);
          }
          if (
            selectedSearchesByStatus.includes("in progress") &&
            shift.tasks.some(
              (t) =>
                (t.timesheet?.signon?.signature &&
                  !t.timesheet?.signoff?.signature) ||
                (shift.release_status > 0 && !t.timesheet?.signon?.signature)
            )
          ) {
            uniqShifts.push(shift.uuid);
            shifts.push(shift);
          }
          if (
            selectedSearchesByStatus.includes("draft") &&
            shift.release_status === 0
          ) {
            uniqShifts.push(shift.uuid);
            shifts.push(shift);
          }
          if (
            selectedSearchesByStatus.includes("cancelled") &&
            shift.tasks.some((t) => t.resource.release_status === 3)
          ) {
            uniqShifts.push(shift.uuid);
            shifts.push(shift);
          }
        }
      });
      return shifts;
    }
    return listShifts;

    // return filteredValue;
    // const hasStringValues:boolean = Array.isArray(selectedValuesString) ? selectedValuesString.length > 0 : selectedValuesString !== '';
    // const selectedValuesArray = Array.isArray(selectedValuesString) ? selectedValuesString : [selectedValuesString];
    // const regexPattern = new RegExp(
    //   selectedValuesArray.map(keyword =>
    //     keyword?.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    //   ).map(keyword => `(${keyword?.split(' ').join('.*')})`).join(''),
    //   'i'
    // );
    //
    // let filteredValue = list?.filter(list => {
    //     const nameMatch:boolean = regexPattern.test(list?.detail?.name?.toLowerCase().replace(/ /g, ''));
    //   return (hasStringValues ? nameMatch : true)
    //
    // });
    //
    // return filteredValue;

    // if(type == 'reserveShifts') {
    //   const hasStringValues:boolean = Array.isArray(selectedValuesString) ? selectedValuesString.length > 0 : selectedValuesString !== '';
    //   const selectedValuesArray = Array.isArray(selectedValuesString) ? selectedValuesString : [selectedValuesString];
    //     const regexPattern = new RegExp(
    //       selectedValuesArray.map(keyword =>
    //         keyword?.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    //       ).map(keyword => `(${keyword?.split(' ').join('.*')})`).join(''),
    //       'i'
    //     );
    //
    //   const hasSelectedStatus:boolean = selectedSearchesByStatus?.length > 0;
    //   const hasSelectedVenue:boolean = selectedSearchesByVenue?.length > 0;
    //   const hasSelectedResource:boolean = selectedSearchesByResource?.length > 0;
    //   const hasSelectedSupplier:boolean = selectedSearchesBySupplier?.length > 0;
    //   let filteredValue = list?.filter(list => {
    //     const stringResourceNameMatch = list?.tasks?.some(t=> selectedSearchesByResource?.includes(t.resource?.name));
    //
    //     const stringSupplierNameMatch:boolean = list?.tasks?.some(t=> regexPattern.test(t?.supplier?.name?.toLowerCase().replace(/ /g, '')))
    //     const stringVenueNameMatch:boolean = selectedSearchesByVenue.includes(list.venue.name) ? list.venue.name : '';
    //       // regexPattern.test(list?.venue?.name?.toLowerCase().replace(/ /g, ''))
    //     const statusNameMatch:boolean = (selectedSearchesByStatus?.includes('completed') && list?.tasks?.some(t => t.timesheet?.signoff?.signature)) || (selectedSearchesByStatus?.includes('in progress') && list?.tasks.some(t => t.timesheet?.signon?.signature && !t.timesheet?.signoff?.signature)) || (selectedSearchesByStatus?.includes('cancelled') && list?.tasks.some(t => t.resource.release_status === 3)) || (selectedSearchesByStatus?.includes('draft') && list?.release_status === 0)
    //     const venueNameMatch = selectedSearchesByVenue?.includes(list?.venue?.name);
    //     const resourceNameMatch:boolean =  list?.tasks?.some(t=> selectedSearchesByResource?.includes(t.resource?.name));
    //     const supplierNameMatch:boolean =  list?.tasks?.some(t=> selectedSearchesBySupplier?.includes(t.supplier?.name));
    //     debugger;
    //     return (hasSelectedStatus ? statusNameMatch : true)
    //       && (hasSelectedVenue ? venueNameMatch : true)
    //       && (hasSelectedResource ? resourceNameMatch : true)
    //       && (hasSelectedSupplier ? supplierNameMatch : true)
    //       && (hasStringValues ? (stringResourceNameMatch || stringSupplierNameMatch || stringVenueNameMatch) : true)
    //   });
    //
    //   return filteredValue;
    // }
  }
}
