export class MembersFakeDb {
  public static members = [
    {
      id: 44444444,
      uuid: 44444444,
      user_id: 44444444,
      name: "Mike Morison",
      email: "mike@gmail.com",
      mobile: "56645764567",
      address:
        "Udyog Vihar Phase V, Phase V, Udyog Vihar, Sector 19, Gurugram, Haryana, India",
      role: 0,
      role_name: "DirectResource",
      status: 0,
      department: "Department 1",
    },
    {
      id: 55555555,
      uuid: 55555555,
      user_id: 55555555,
      name: "Kevin Ross",
      email: "kevin@gmail.com",
      mobile: "6345676544546",
      address: "Rue du Vieux Versailles, Versailles, France",
      role: 1,
      role_name: "Manager",
      status: 1,
      department: "Department 2",
    },
    {
      id: 66666666,
      uuid: 66666666,
      user_id: 66666666,
      name: "Jessica Alba",
      email: "jessica@gmail.com",
      mobile: "6545654546",
      address: "Notting Hill Gate, London, UK",
      role: 3,
      role_name: "Administrator",
      status: 0,
      department: "Department 3",
    },
    {
      id: 11111111,
      uuid: 11111111,
      user_id: 11111111,
      name: "Jessica Alba",
      email: "jessica@gmail.com",
      mobile: "6545654546",
      address: "Notting Hill Gate, London, UK",
      role: 3,
      role_name: "Manager",
      status: 0,
      department: "Department 3",
    },
  ];
}
