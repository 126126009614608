import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { FuseNavigationModule } from "@fuse/components/navigation";
import { FuseFullscreenModule } from "@fuse/components/fullscreen/fullscreen.module";
import { MessagesModule } from "app/layout/common/messages/messages.module";
import { NotificationsModule } from "app/layout/common/notifications/notifications.module";
import { SearchModule } from "app/layout/common/search/search.module";
import { ShortcutsModule } from "app/layout/common/shortcuts/shortcuts.module";
import { UserMenuModule } from "app/layout/common/user-menu/user-menu.module";
import { PrimaryAccountMenuModule } from "app/layout/common/primary-account-menu/primary-account-menu.module";
import { SharedModule } from "app/shared/shared.module";
import { ClassyLayoutComponent } from "app/layout/layouts/vertical/classy/classy.component";

@NgModule({
  declarations: [ClassyLayoutComponent],
  imports: [
    HttpClientModule,
    RouterModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    FuseFullscreenModule,
    FuseNavigationModule,
    MessagesModule,
    NotificationsModule,
    SearchModule,
    ShortcutsModule,
    UserMenuModule,
    PrimaryAccountMenuModule,
    SharedModule,
  ],
  exports: [ClassyLayoutComponent],
})
export class ClassyLayoutModule {}
