import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterTimesheetRegister",
})
export class FilterTimesheetRegisterPipe implements PipeTransform {
  transform(list, selectedValuesString, selectedStatusType, type) {
    if (type === "forView") {
      // console.log("check selectedStatusType: ", selectedStatusType)
      // console.log("lets check list: ", list )
      // console.log("lets check selectedValuesString: ", selectedValuesString )
      // console.log("lets check selectedSearchesByVenue: ", selectedSearchesByVenue )
      // const hasSelectedClients:boolean = selectedSearchesByClient.length > 0;
      // const hasSelectedVenues:boolean = selectedSearchesByVenue.length > 0;
      const hasStringValues: boolean = Array.isArray(selectedValuesString)
        ? selectedValuesString?.length > 0
        : selectedValuesString !== "";
      const selectedValuesArray = Array.isArray(selectedValuesString)
        ? selectedValuesString
        : [selectedValuesString];
      const hasStatusValuesFinalised: boolean =
        selectedStatusType.includes("finalised");
      const hasStatusValuesUnaccepted: boolean =
        selectedStatusType.includes("unaccepted");
      const hasStatusValuesUnworked: boolean =
        selectedStatusType.includes("unworked");
      const hasStatusValues: boolean = selectedStatusType?.length > 0;

      const regexPattern = new RegExp(
        selectedValuesArray
          .map((keyword) =>
            keyword?.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
          )
          .map((keyword) => `(${keyword.split(" ").join(".*")})`)
          .join(""),
        "i"
      );
      list?.forEach((item) => {
        item.venues.forEach((venue) => {
          venue.tasks = venue?.tasks?.filter((shifts) => {
            const stringResourceNameMatch: boolean = regexPattern.test(
              shifts?.resource?.name?.toLowerCase().replace(/ /g, "")
            );
            const stringSupplierNameMatch: boolean = regexPattern.test(
              shifts?.supplier?.name?.toLowerCase().replace(/ /g, "")
            );
            const finalisedMatch: boolean =
              shifts?.editing == false &&
              shifts?.timesheet?.signon?.signature &&
              shifts?.timesheet?.signoff?.signature &&
              shifts?.resource?.release_status == 2;
            const unacceptedMatch: boolean =
              shifts?.editing == false &&
              shifts?.resource?.release_status !== 2;
            const unworkedMatch: boolean =
              shifts?.editing == false &&
              shifts?.resource?.release_status == 2 &&
              !shifts?.timesheet?.signon?.signature;

            const statusMatch: boolean =
              (selectedStatusType.includes("finalised") &&
                shifts?.timesheet?.signon?.signature &&
                shifts?.timesheet?.signoff?.signature &&
                shifts?.resource?.release_status == 2) ||
              (selectedStatusType.includes("unaccepted") &&
                shifts?.resource?.release_status !== 2) ||
              (selectedStatusType.includes("unworked") &&
                shifts?.resource?.release_status == 2 &&
                !shifts?.timesheet?.signon?.signature);

            // console.log("lets check shifts: ",shifts)
            // console.log("lets check stringResourceNameMatch: ",stringResourceNameMatch)
            // console.log("lets check stringResourceNameMatch: ",stringResourceNameMatch)
            return (
              (hasStringValues
                ? stringResourceNameMatch || stringSupplierNameMatch
                : true) && (hasStatusValues ? statusMatch : true)
            );
            // && (hasStatusValuesFinalised ? finalisedMatch : true) && (hasStatusValuesUnaccepted ? unacceptedMatch : true) && (hasStatusValuesUnworked ? unworkedMatch : true)
          });
        });
        // item.tasks = item.tasks.filter(shifts => {
        //     const stringResourceNameMatch:boolean = regexPattern.test(shifts?.resource?.name?.toLowerCase().replace(/ /g, ''))
        //     const stringSupplierNameMatch:boolean = regexPattern.test(shifts?.supplier?.name?.toLowerCase().replace(/ /g, ''))
        //     // console.log("lets check shifts: ",shifts)
        //     // console.log("lets check stringResourceNameMatch: ",stringResourceNameMatch)
        //     // console.log("lets check stringResourceNameMatch: ",stringResourceNameMatch)
        //     return (hasStringValues ? (stringResourceNameMatch || stringSupplierNameMatch)  : true)
        //   })
        // console.log("lets check filteredTasks: ", filteredTasks)
        // const clientNameMatch:boolean = selectedSearchesByClient?.includes(list?.shift?.client?.detail?.name)
        // const venueNameMatch:boolean = selectedSearchesByVenue?.includes(list?.shift?.venue?.name)
        // return { ...item};
        // const nameMatch:boolean = regexPattern.test(list?.task?.resource?.name.toLowerCase().replace(/ /g, ''));
        // return (hasStringValues ? (nameMatch )  : true)
        // return ;
        // && (hasSelectedClients ? clientNameMatch:true) && (hasSelectedVenues ? venueNameMatch:true)
      });
      // console.log("lets check filteredValue: ", filteredValue)
      // console.log("check the list: ", list)
      return list;
    }
    if (type === "shifts") {
      // console.log("check selectedStatusType: ", selectedStatusType)
      // console.log("lets check list shifts: ", list )

      const hasStringValues: boolean = Array.isArray(selectedValuesString)
        ? selectedValuesString?.length > 0
        : selectedValuesString !== "";
      const hasStatusValuesFinalised: boolean =
        selectedStatusType.includes("finalised");
      const hasStatusValuesUnaccepted: boolean =
        selectedStatusType.includes("unaccepted");
      const hasStatusValuesUnworked: boolean =
        selectedStatusType.includes("unworked");
      const hasStatusValues: boolean = selectedStatusType?.length > 0;

      const selectedValuesArray = Array.isArray(selectedValuesString)
        ? selectedValuesString
        : [selectedValuesString];
      const regexPattern = new RegExp(
        selectedValuesArray
          .map((keyword) =>
            keyword?.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
          )
          .map((keyword) => `(${keyword.split(" ").join(".*")})`)
          .join(""),
        "i"
      );
      list?.forEach((item) => {
        item.tasks = item.tasks.filter((shifts) => {
          const stringResourceNameMatch: boolean = regexPattern.test(
            shifts?.resource?.name?.toLowerCase().replace(/ /g, "")
          );
          const stringSupplierNameMatch: boolean = regexPattern.test(
            shifts?.supplier?.name?.toLowerCase().replace(/ /g, "")
          );
          const statusMatch: boolean =
            (selectedStatusType.includes("finalised") &&
              shifts?.timesheet?.signon?.signature &&
              shifts?.timesheet?.signoff?.signature &&
              shifts?.resource?.release_status == 2) ||
            (selectedStatusType.includes("unaccepted") &&
              shifts?.resource?.release_status !== 2) ||
            (selectedStatusType.includes("unworked") &&
              shifts?.resource?.release_status == 2 &&
              !shifts?.timesheet?.signon?.signature);

          const finalisedMatch: boolean =
            shifts?.editing == false &&
            shifts?.timesheet?.signon?.signature &&
            shifts?.timesheet?.signoff?.signature &&
            shifts?.resource?.release_status == 2;
          const unacceptedMatch: boolean =
            shifts?.editing == false && shifts?.resource?.release_status !== 2;
          const unworkedMatch: boolean =
            shifts?.editing == false &&
            shifts?.resource?.release_status == 2 &&
            !shifts?.timesheet?.signon?.signature;

          // const venueNameMatch:boolean = selectedSearchesByVenue?.includes(list?.shift?.venue?.name)
          // console.log("lets check shifts: ",shifts)
          // console.log("lets check stringResourceNameMatch: ",stringResourceNameMatch)
          // console.log("lets check stringResourceNameMatch: ",stringResourceNameMatch)
          return (
            (hasStringValues
              ? stringResourceNameMatch || stringSupplierNameMatch
              : true) && (hasStatusValues ? statusMatch : true)
          );
          // && (hasStatusValuesFinalised ? finalisedMatch : true)
          // && (hasStatusValuesUnaccepted ? unacceptedMatch : true) && (hasStatusValuesUnworked ? unworkedMatch : true)
        });

        // item.tasks = item.tasks.filter(shifts => {
        //     const stringResourceNameMatch:boolean = regexPattern.test(shifts?.resource?.name?.toLowerCase().replace(/ /g, ''))
        //     const stringSupplierNameMatch:boolean = regexPattern.test(shifts?.supplier?.name?.toLowerCase().replace(/ /g, ''))
        //     // console.log("lets check shifts: ",shifts)
        //     // console.log("lets check stringResourceNameMatch: ",stringResourceNameMatch)
        //     // console.log("lets check stringResourceNameMatch: ",stringResourceNameMatch)
        //     return (hasStringValues ? (stringResourceNameMatch || stringSupplierNameMatch)  : true)
        //   })
        // console.log("lets check filteredTasks: ", filteredTasks)
        // const clientNameMatch:boolean = selectedSearchesByClient?.includes(list?.shift?.client?.detail?.name)
        // const venueNameMatch:boolean = selectedSearchesByVenue?.includes(list?.shift?.venue?.name)
        // return { ...item};
        // const nameMatch:boolean = regexPattern.test(list?.task?.resource?.name.toLowerCase().replace(/ /g, ''));
        // return (hasStringValues ? (nameMatch )  : true)
        // return ;
        // && (hasSelectedClients ? clientNameMatch:true) && (hasSelectedVenues ? venueNameMatch:true)
      });
      // console.log("lets check filteredValue: ", filteredValue)
      // console.log("check the list: ", list)
      return list;
    }
  }
}
