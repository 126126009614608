import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterIncidentReport",
})
export class FilterIncidentReportPipe implements PipeTransform {
  transform(
    list,
    selectedValuesString,
    selectedSearchesByClient,
    selectedSearchesByVenue
  ) {
    // console.log("lets check list: ", list )
    // console.log("lets check selectedSearchesByClient: ", selectedSearchesByClient )
    // console.log("lets check selectedSearchesByVenue: ", selectedSearchesByVenue )
    const hasSelectedClients: boolean = selectedSearchesByClient.length > 0;
    const hasSelectedVenues: boolean = selectedSearchesByVenue.length > 0;
    const hasStringValues: boolean = Array.isArray(selectedValuesString)
      ? selectedValuesString.length > 0
      : selectedValuesString !== "";
    const selectedValuesArray = Array.isArray(selectedValuesString)
      ? selectedValuesString
      : [selectedValuesString];
    const regexPattern = new RegExp(
      selectedValuesArray
        .map((keyword) =>
          keyword?.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
        )
        .map((keyword) => `(${keyword.split(" ").join(".*")})`)
        .join(""),
      "i"
    );
    let filteredValue = list?.filter((list) => {
      const clientNameMatch: boolean = selectedSearchesByClient?.includes(
        list?.shift?.client?.detail?.name
      );
      const venueNameMatch: boolean = selectedSearchesByVenue?.includes(
        list?.shift?.venue?.name
      );
      const nameMatch: boolean = regexPattern.test(
        list?.task?.resource?.name.toLowerCase().replace(/ /g, "")
      );
      return (
        (hasStringValues ? nameMatch : true) &&
        (hasSelectedClients ? clientNameMatch : true) &&
        (hasSelectedVenues ? venueNameMatch : true)
      );
    });

    return filteredValue;
  }
}
