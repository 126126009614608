export class HomeStatisticsFakeDb {
  public static statistics = [
    {
      name: "Tasks Accepted",
      total: 11,
      icon: "trending_up",
      colorScheme: {
        domain: ["#41a4f5", "#41a4f5", "#41a4f5", "#41a4f5", "#41a4f5"],
      },
      percent: 17,
      graph: [
        { name: "2020-01-01", value: 3 },
        { name: "2020-01-02", value: 15 },
        { name: "2020-01-03", value: 43 },
        { name: "2020-01-04", value: 32 },
        { name: "2020-01-05", value: 24 },
        { name: "2020-01-06", value: 12 },
        { name: "2020-01-07", value: 10 },
      ],
    },
    {
      name: "Tasks Declined",
      total: 23,
      icon: "trending_up",
      colorScheme: {
        domain: ["#41a4f5", "#41a4f5", "#41a4f5", "#41a4f5", "#41a4f5"],
      },
      percent: 27,
      graph: [
        { name: "2020-01-01", value: 3 },
        { name: "2020-01-02", value: 15 },
        { name: "2020-01-03", value: 43 },
        { name: "2020-01-04", value: 32 },
        { name: "2020-01-05", value: 24 },
        { name: "2020-01-06", value: 12 },
        { name: "2020-01-07", value: 10 },
      ],
    },
    {
      name: "Total Tasks",
      total: 19,
      icon: "trending_up",
      colorScheme: {
        domain: ["#41a4f5", "#41a4f5", "#41a4f5", "#41a4f5", "#41a4f5"],
      },
      percent: 31,
      graph: [
        { name: "2020-01-01", value: 3 },
        { name: "2020-01-02", value: 15 },
        { name: "2020-01-03", value: 43 },
        { name: "2020-01-04", value: 32 },
        { name: "2020-01-05", value: 24 },
        { name: "2020-01-06", value: 12 },
        { name: "2020-01-07", value: 10 },
      ],
    },
    {
      name: "Clients",
      total: 14,
      icon: "trending_down",
      colorScheme: {
        domain: ["red", "red", "red", "red", "red"],
      },
      percent: -27,
      graph: [
        { name: "2020-01-01", value: 3 },
        { name: "2020-01-02", value: 15 },
        { name: "2020-01-03", value: 43 },
        { name: "2020-01-04", value: 32 },
        { name: "2020-01-05", value: 24 },
        { name: "2020-01-06", value: 12 },
        { name: "2020-01-07", value: 10 },
      ],
    },
    {
      name: "Resources",
      total: 14,
      icon: "trending_down",
      colorScheme: {
        domain: ["red", "red", "red", "red", "red"],
      },
      percent: -27,
      graph: [
        { name: "2020-01-01", value: 3 },
        { name: "2020-01-02", value: 15 },
        { name: "2020-01-03", value: 43 },
        { name: "2020-01-04", value: 32 },
        { name: "2020-01-05", value: 24 },
        { name: "2020-01-06", value: 12 },
        { name: "2020-01-07", value: 10 },
      ],
    },
    {
      name: "Suppliers",
      total: 14,
      icon: "trending_down",
      colorScheme: {
        domain: ["red", "red", "red", "red", "red"],
      },
      percent: -27,
      graph: [
        { name: "2020-01-01", value: 3 },
        { name: "2020-01-02", value: 15 },
        { name: "2020-01-03", value: 43 },
        { name: "2020-01-04", value: 32 },
        { name: "2020-01-05", value: 24 },
        { name: "2020-01-06", value: 12 },
        { name: "2020-01-07", value: 10 },
      ],
    },
  ];
}
