import * as moment from "moment";

export class InvitationsFakeDb {
  public static invitations = [
    {
      invite_id: "",
      account_id: "c1c7eabe-1a0d-440c-9461-c56de2d01044",
      account_name: "Stan & Co Pty Ltd",
      trading_name: "Robert's Super Security Firm Pty Ltd",
      account_logo: "",
      account_abn: "80126718134",
      contact_name: "Test Test",
      contact_email: "test-user-10@test.com",
      contact_phone: "+61 2 3233 9392",
      account_address: "",
      role: "Administrator",
      status: "Active",
      date_label: "Since Active",
      date: "2023-04-10T02:00:19.575Z",
    },
    {
      invite_id: "",
      account_id: "baa42959-4151-45f7-a950-228450af312f",
      account_name: "SCOTT, SIOBHAN ANNE CATRINA",
      trading_name: "SCOTT, SIOBHAN ANNE CATRINA",
      account_logo: "",
      account_abn: "70868021568",
      contact_name: "Test 1 Resource",
      contact_email: "test-res-1@test.com",
      contact_phone: "+61232421321",
      account_address: "",
      role: "Administrator",
      status: "Active",
      date_label: "Since Active",
      date: "2023-04-05T00:30:56.919Z",
    },
    {
      invite_id: "",
      account_id: "6c3e8a63-d8c2-4b78-a8d9-d7f923253c96",
      account_name: "J.A WIFFEN & K.J WIFFEN",
      trading_name: "J.A WIFFEN & K.J WIFFEN",
      account_logo: "",
      account_abn: "72693808408",
      contact_name: "Fred 85 Test",
      contact_email: "ft+85@mail.com",
      contact_phone: "+61 412 858 585",
      account_address: "",
      role: "DirectResource",
      status: "Active",
      date_label: "Since Active",
      date: "2023-02-22T04:00:49.702Z",
    },
    {
      invite_id: "",
      account_id: "554ee994-7065-49a1-bb46-3efaf8513ed9",
      account_name: "CALVI FAMILY TRUST",
      trading_name: "CALVI FAMILY TRUST",
      account_logo:
        "https://devdoc.gravityfusion.com.au/logo/554ee994-7065-49a1-bb46-3efaf8513ed9.png?1676863142400",
      account_abn: "54237530532",
      contact_name: "Fred 92 Test",
      contact_email: "ft+92@mail.com",
      contact_phone: "+61412929292",
      account_address:
        "92 Nguyễn Hữu Cảnh, Phường 22, Bình Thạnh, Thành phố Hồ Chí Minh 700000, Vietnam",
      role: "DirectResource",
      status: "Pending",
      date_label: "Since Active",
      date: "2023-02-16T22:57:24.647Z",
    },
    {
      invite_id: "",
      account_id: "81e2d6eb-5ce0-4d71-a430-f4735fb6e1d7",
      account_name: "THE TRUSTEE FOR THE MELON FAMILY TRUST",
      trading_name: "THE TRUSTEE FOR THE MELON FAMILY TRUST",
      account_logo:
        "https://devdoc.gravityfusion.com.au/logo/81e2d6eb-5ce0-4d71-a430-f4735fb6e1d7.png?1679534167137",
      account_abn: "43883237605",
      contact_name: "Fred 99 Test",
      contact_email: "ft+99@mail.com",
      contact_phone: "+61 412 999 999",
      account_address:
        "No. 99, Fuxing N Rd, Songshan District, Taipei City, Taiwan 10491",
      role: "Manager",
      status: "Pending",
      date_label: "Since Active",
      date: "2023-04-04T03:12:26.13Z",
    },
    {
      invite_id: "",
      account_id: "12341234134",
      account_name: "THE TRUSTEE FOR THE MELON FAMILY TRUST",
      trading_name: "THE TRUSTEE FOR THE MELON FAMILY TRUST",
      account_logo:
        "https://devdoc.gravityfusion.com.au/logo/81e2d6eb-5ce0-4d71-a430-f4735fb6e1d7.png?1679534167137",
      account_abn: "43883237605",
      contact_name: "Darya Test",
      contact_email: "darya@gmail.com",
      contact_phone: "+61 412 999 999",
      account_address:
        "No. 99, Fuxing N Rd, Songshan District, Taipei City, Taiwan 10491",
      role: "Administrator",
      status: "Active",
      date_label: "Since Active",
      date: "2023-04-04T03:12:26.13Z",
    },
  ];
}
