import { MembersFakeDb } from "./members";
import { VenuesFakeDb } from "./venues";

export class ClientsFakeDb {
  public static clients = [
    {
      id: 56464757411,
      uuid: "56464757411",
      detail: {
        avatar: "https://picsum.photos/100?random=5",
        individual: false,
        primaryindustry: "Security",
        contactEmail: "frederic@gmail.com",
        abn: "56464757411",
        acn: "2378273982798789",
        name: "Selvador Kris",
        postAddress:
          "Rua Quinta dos Morgados YY, Barreiro e Lavradio, Portugal",
        primaryAddress: "15 Debenham St, Mawson, ACT",
        tradingname: "Super Security",
        entitytype: "Sole Trader",
        phoneNumber: "4564363344",
        emails: [],
      },
      invitation: {
        date_invited: "0001-01-01T00:00:00Z",
        date_resolved: "0001-01-01T00:00:00Z",
        email: "",
        invite_name: "",
        invitee_id: "",
        primary_account_detail: {
          industry: "",
          name: "",
          phone: "",
          primary_address: "",
          postal_address: "",
        },
        primary_account_id: "",
        sender: { user_id: "", email: "", mobile: "", department: "", role: 0 },
        sms: "",
        status: 0,
        sub_account_detail: {
          industry: "",
          name: "",
          phone: "",
          primary_address: "",
          postal_address: "",
        },
        sub_account_id: "",
        sub_account_type: "",
        uuid: "tr566rtfr6tyyu",
      },
      connection_request: {
        date_requested: "0001-01-01T00:00:00Z",
        history: null,
        invited_account_uuid: "",
        message: "",
        relationship: "",
        request_account_uuid: "34resrfw34534ret45ter43efae",
      },
      connection_status: 0,
      members: MembersFakeDb.members,
      documents: [],
      accreditation: [
        {
          uuid: "09506064-06f8-42b3-919f-867207b1bfa0",
          category: "Security Licence",
          accreditation: "NSW Security Licence",
          number: "410459957",
          startdate: "2010-10-10",
          enddate: "2020-11-11",
          primary: true,
          updated: "2020-12-12:10:00:00Z",
          pinged: "2020-12-12:10:00:00Z",
          type: "certificate",
          status: "Expired",
          classes: [
            {
              code: "",
              name: "Unarmed Guard",
            },
          ],
          documents: [
            {
              type: "Accreditation",
              accreditation: "NSW Security",
              title: "Security document.",
              description: "Some description",
              private: true,
              primary: true,
              location: "/usr/whatever",
            },
          ],
        },
      ],
      contacts: [],
      venues: VenuesFakeDb.venues,
      tasks: [],
    },
    {
      id: 98746563111,
      uuid: "98746563111",
      detail: {
        avatar: "https://picsum.photos/100?random=6",
        individual: false,
        primaryindustry: "Security",
        abn: "98746563111",
        acn: "3454566663322",
        primaryAddress: "",
        postAddress: "",
        name: "Great Man",
        contactEmail: "shara@gmail.com",
        tradingname: "Super Security",
        entitytype: "Sole Trader",
        phoneNumber: "4534535667",
        emails: [],
      },
      invitation: {
        date_invited: "0001-01-01T00:00:00Z",
        date_resolved: "0001-01-01T00:00:00Z",
        email: "",
        invite_name: "",
        invitee_id: "",
        primary_account_detail: {
          industry: "",
          name: "",
          phone: "",
          primary_address: "",
          postal_address: "",
        },
        primary_account_id: "",
        sender: { user_id: "", email: "", mobile: "", department: "", role: 0 },
        sms: "",
        status: 0,
        sub_account_detail: {
          industry: "",
          name: "",
          phone: "",
          primary_address: "",
          postal_address: "",
        },
        sub_account_id: "",
        sub_account_type: "",
        uuid: "tr566rtfr6tyyu",
      },
      connection_request: {
        date_requested: "0001-01-01T00:00:00Z",
        history: null,
        invited_account_uuid: "",
        message: "",
        relationship: "",
        request_account_uuid: "12341234134",
      },
      connection_status: 0,
      documents: [],
      members: MembersFakeDb.members,
      accreditation: [],
      contacts: [],
      venues: VenuesFakeDb.venues,
      tasks: [],
    },
  ];

  public static entitytypes = [
    "Approved Deposit Fund",
    "APRA Regulated Fund(Fund Type Unknown)",
    "Commonwealth Government Public Company",
    "Commonwealth Government Co-operative",
    "Commonwealth Government Limited Partnership",
    "Commonwealth Government Other Unincorporated Entity",
    "Commonwealth Government Other Incorporated Entity",
    "Commonwealth Government Pooled Development Fund",
    "Commonwealth Government Private Company",
    "Commonwealth Government Strata Title",
    "Commonwealth Government Public Trading Trust",
    "Commonwealth Government Corporate Unit Trust",
    "Commonwealth Government Statutory Authority",
    "Commonwealth Government Company",
    "Commonwealth Government Entity",
    "Commonwealth Government Partnership",
    "Commonwealth Government Super Fund",
    "Commonwealth Government Trust",
    "Cash Management Trust",
    "Co-operative",
    "Commonwealth Government APRA Regulated Public Sector Fund",
    "Commonwealth Government APRA Regulated Public Sector Scheme",
    "Commonwealth Government Non-Regulated Super Fund",
    "Commonwealth Government Cash Management Trust",
    "Commonwealth Government Discretionary Services Management Trust",
    "Commonwealth Government Fixed Trust",
    "Commonwealth Government Hybrid Trust",
    "Commonwealth Government Discretionary Investment Trust",
    "Commonwealth Government Listed Public Unit Trust",
    "Commonwealth Government Unlisted Public Unit Trust",
    "Commonwealth Government Discretionary Trading Trust",
    "Commonwealth Government Fixed Unit Trust",
    "Corporate Unit Trust",
    "Deceased Estate",
    "Diplomatic/Consulate Body or High Commissioner",
    "Discretionary Investment Trust",
    "Discretionary Services Management Trust",
    "Discretionary Trading Trust",
    "First Home Saver Accounts Trust",
    "Family Partnership",
    "Fixed Unit Trust",
    "Fixed Trust",
    "Hybrid Trust",
    "Individual/Sole Trader",
    "Local Government Public Company",
    "Local Government Co-operative",
    "Local Government Limited PartnerShip",
    "Local Government Other Unincorporated Entity",
    "Local Government Pooled Development Fund",
    "Local Government Private Company",
    "Local Government Strata Title",
    "Local Government Public Trading Trust",
    "Local Government Corporate Unit Trust",
    "Local Government Statutory Authority",
    "Local Government Company",
    "Local Government Entity",
    "Local Government Partnership",
    "Local Government Trust",
    "Limited Partnership",
    "Local Government APRA Regulated Public Sector Fund",
    "Local Government APRA Regulated Public Sector Scheme",
    "Local Government Non-Regulated Super Fund",
    "Local Government Cash Management Trust",
    "Local Government Discretionary Services Management Trust",
    "Local Government Fixed Trust",
    "Local Government Hybrid Trust",
    "Local Government Discretionary Investment Trust",
    "Local Government Listed Public Unit Trust",
    "Local Government Unlisted Public Unit Trust",
    "Local Government Discretionary Trading Trust",
    "Local Government Fixed Unit Trust",
    "APRA Regulated Non-Public Offer Fund",
    "Non-Regulated Superannuation Fund",
    "Other Incorporated Entity",
    "Pooled Development Fund",
    "APRA Regulated Public Offer Fund",
    "Unlisted Public Unit Trust",
    "Australian Private Company",
    "Pooled Superannuation Trust",
    "Other Partnership",
    "Public Trading Trust",
    "Australian Public Company",
    "Listed Public Unit Trust",
    "Small APRA Regulated Fund",
    "State Government Public Company",
    "State Government Co-operative",
    "State Government Limited Partnership",
    "State Government Other Unincorporated Entity",
    "State Government Other Incorporated Entity",
    "State Government Pooled Development Fund",
    "State Government Private Company",
    "State Government Strata Title",
    "State Government Public Trading Trust",
    "State Government Corporate Unit Trust",
    "State Government Statutory Authority",
    "State Government Company",
    "State Government Entity",
    "State Government Partnership",
    "State Government Trust",
    "ATO Regulated Self-Managed Superannuation Fund",
    "State Government APRA Regulated Public Sector Fund",
    "State Government APRA Regulated Public Sector Scheme",
    "State Government Non-Regulated Super Fund",
    "State Government Cash Management Trust",
    "State Government Discretionary Services Management Trust",
    "State Government Fixed Trust",
    "State Government Hybrid Trust",
    "State Government Discretionary Investment Trust",
    "State Government Listed Public Unit Trust",
    "State Government Unlisted Public Unit Trust",
    "Strata Title",
    "State Government Discretionary trading Trust",
    "State Government Fixed Unit Trust",
    "Super Fund",
    "Territory Government Public Company",
    "Territory Government Co-operative",
    "Territory Government Limited Partnership",
    "Territory Government Other Unincorporated Entity",
    "Territory Government Other Incorporated Entity",
    "Territory Government Pooled Development Fund",
    "Territory Government Private Company",
    "Territory Government Strata Title",
    "Territory Government Public Trading Trust",
    "Territory Government Corporate Unit Trust",
    "Territory Government Statutory Authority",
    "Territory Government Entity",
    "Territory Government Partnership",
    "Territory Government Trust",
    "Other trust",
    "Territory Government APRA Regulated Public Sector Fund",
    "Territory Government APRA Regulated Public Sector Scheme",
    "Territory Government Non-Regulated Super Fund",
    "Territory Government Cash Management Trust",
    "Territory Government Discretionary Services Management Trust",
    "Territory Government Fixed Trust",
    "Territory Government Hybrid Trust",
    "Territory Government Discretionary Investment Trust",
    "Territory Government Listed Public Unit Trust",
    "Territory Government Unlisted Public Init Trust",
    "Territory Government Discretionary Trading Trust",
    "Territory Government Fixed Unit Trust",
    "Other Unincorporated Entity",
  ];

  public static typesWALiquor = [
    "Club - Club Restricted",
    "Hotel - Hotel Restricted - Tavern - Tavern Restricted - Small Bar",
    "Casino Liquor Licence",
    "Special Facility",
    "Producer",
    "Night Club",
    "Occasional",
    "Restaurant",
    "WholeSaler",
    "Liquor Stores",
  ];

  public static controlsWASecurity = [
    "licenceNumberWA",
    "expiresAtWASecurity",
    "crowdControlAgent",
    "crowdController",
    "inquiryAgent",
    "investigator",
    "securityAgent",
    "securityBodyguard",
    "securityConsultant",
    "securityInstaller",
    "securityOfficer",
    "securityCompany",
  ];
  public static accreditationTypes = [
    "NSW Security Licence",
    "VIC Security Licence",
    "QLD Security Licence",
    "WA Security Licence",
    "NSW Liquor Licence",
    "VIC Liquor Licence",
    "QLD Liquor Licence",
    "WA Liquor Licence",
  ];
}
