export class ReportsFakeDb {
  public static reports = [
    {
      id: "d41261e3-4afc-4a39-8571-8a6aa1239b6a",
      "Action Taken / Incident Details": "Yes",
      "Additional Incident Details (if required)": "",
      Age: "26-34",
      "Ambulance Attended": false,
      "Anti-social Behaviour": false,
      Appearance: "Caucasian",
      "Asked To Leave": true,
      "Asked to Leave - Fail to Quit": false,
      "Bar Area": false,
      "Clothing - Bottom": "rgsedfgr",
      "Clothing - Top": "drfsfa",
      Complaint: false,
      "Crime Scene Preserved": false,
      "Discharge Firearm": false,
      "Dog Attack": false,
      "Fail to Quit Notice Issued": false,
      "First Aid Treatment Supplied": false,
      "Front Door": false,
      Gaming: false,
      "Gaming Room": false,
      Gender: "Female",
      "Incident Summary Report": "fgbsfrtgbas",
      "Injury / Medical Assistance": false,
      "Linked to any other incidents?": true,
      "Main Bar": false,
      "Malicious Damage": true,
      Minors: false,
      Name: "Sebastian",
      "Name (if required)": "drfesrf",
      "Off Venue (50m Within Venue)": false,
      "Other (Please Specify": false,
      "Other Details": "srfgaedfv",
      "Other Details (if required)": "fgdsfgva",
      "Patron Asked to Leave": false,
      "Patron Refused Entry": false,
      "Patron Refused Service": false,
      Phone: "45353373",
      "Phone (if required)": "75678785",
      "Police / OLGR Inspection": false,
      "Police Called by Venue Staff": false,
      "Police Involved": false,
      "Refuse Entry": true,
      "Refuse Service": true,
      "Remove Firearm from Holster": false,
      "Security Attended": false,
      "Self Exclusion": false,
      "Serious Other (Please Specify)": false,
      "Smoking Area": false,
      "Taxi Offered?": false,
      Theft: true,
      "Violence - Brawl/Affray": false,
      "Violence - Glassing": false,
      Weapon: false,
    },
  ];
}
