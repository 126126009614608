export class ContactsFakeDb {
  public static contacts = [
    {
      id: "contacts",
      data: [
        {
          uuid: "45g43545uyy33yy3",
          name: "Contact 1",
          email: "contact1@gmail.com",
          phone: "434556765",
          title: "",
          department: "",
          primaryAddress: "",
        },
        {
          uuid: "tregt3454eee",
          name: "Contact 2",
          email: "contact2@gmail.com",
          phone: "3452232",
          title: "",
          department: "",
          primaryAddress: "",
        },
        {
          uuid: "45tede2222edws",
          name: "Contact 3",
          email: "contact3@gmail.com",
          phone: "323476767",
          title: "",
          department: "",
          primaryAddress: "",
        },
      ],
    },
  ];
}
