export class PaymentPlaneFakeDb {
  public static planesMonth = [
    {
      id: "fhy5rfghmng",
      type: "Business Tier one",
      oldPrice: "180",
      newPrice: "150",
      perMonth: "",
      list: [
        "1 Administrators",
        "Up to 5 Sub-Accounts",
        "Up to 8 Venues",
        "Unlimited Resources",
      ],
    },
    {
      id: "f675gh5e33345565",
      type: "Business Tier two",
      oldPrice: "500",
      newPrice: "350",
      perMonth: "",
      list: [
        "Up to 3 Administrators",
        "Up to 8 Sub-Accounts",
        "Up to 21 Venues",
        "Unlimited Resources",
      ],
    },
    {
      id: "3ert4953jkdr",
      type: "Introduction Plan",
      oldPrice: "",
      newPrice: "Free Three Month Trial",
      perMonth: "",
      list: [
        "Up to 3 Administrators",
        "Up to 2 Sub-Accounts",
        "Up to 3 Venues",
        "Unlimited Resources",
      ],
    },
    {
      id: "534edfgdfge4eer",
      type: "Business Tier three",
      oldPrice: "800",
      newPrice: "550",
      perMonth: "",
      list: [
        "Up to 8 Administrators",
        "Up to 21 Sub-Accounts",
        "Up to 34 Venues",
        "Unlimited Resources",
      ],
    },
    {
      id: "rtyr645we4redrtt",
      type: "Business Premium",
      oldPrice: "1200",
      newPrice: "800",
      perMonth: "",
      list: [
        "Unlimited Administrators",
        "UnlimitedSub-Accounts",
        "Up to 55 Venues",
        "Unlimited Resources",
      ],
    },
  ];

  public static planesYear = [
    {
      id: "rd33qdssd366",
      type: "Business Tier one",
      oldPrice: "",
      newPrice: "1620",
      perMonth: "135",
      list: [
        "1 Administrators",
        "Up to 5 Sub-Accounts",
        "Up to 8 Venues",
        "Unlimited Resources",
      ],
    },
    {
      id: "sfw63478ruhesd",
      type: "Business Tier two",
      oldPrice: "",
      newPrice: "3780",
      perMonth: "315",
      list: [
        "Up to 3 Administrators",
        "Up to 8 Sub-Accounts",
        "Up to 21 Venues",
        "Unlimited Resources",
      ],
    },
    {
      id: "sdsfw76e4sdfgg",
      type: "Business Tier three",
      oldPrice: "",
      newPrice: "5940",
      perMonth: "495",
      list: [
        "Up to 8 Administrators",
        "Up to 21 Sub-Accounts",
        "Up to 34 Venues",
        "Unlimited Resources",
      ],
    },
    {
      id: "sdrfser3453423qedasqw000",
      type: "Business Premium",
      oldPrice: "",
      newPrice: "8640",
      perMonth: "720",
      list: [
        "Unlimited Administrators",
        "Unlimited Sub-Accounts",
        "Up to 55 Venues",
        "Unlimited Resources",
      ],
    },
  ];
}
