export class AdminsFakeDb {
  public static admins = [
    {
      authid: "34dfge45345435rfe4",
      uuid: "34dfge45345435rfe4",
      name: "darya",
      primary: true,
      status: "Active",
      role: "Admin",
      email: "darya@gmail.com",
      phone: "342343453453",
    },
    {
      authid: "11111111",
      uuid: "11111111",
      name: "Great Man",
      primary: false,
      role: "Admin",
      status: "Promoting",
      email: "great_man@gmail.com",
      phone: "35345643564555",
    },
    {
      authid: "e4354t55333rrjjjj",
      uuid: "e4354t55333rrjjjj",
      name: "robert",
      primary: false,
      role: "Admin",
      status: "Verifying",
      email: "darya@gmail.com",
      phone: "342343453453",
    },
  ];
}
