export class RolesOfResourcesFakeDb {
  public static roles = [
    {
      role: "Guard",
      rate: "15",
      accreditation: "NSW Security Licence",
      types: "Standard",
    },
    {
      role: "Marshall",
      rate: "20",
      accreditation: "NSW Liquor Licence",
      types: "Discount",
    },
    {
      role: "RSA Marshal",
      rate: "25",
      accreditation: "QLD Liquor Licence",
      types: "Premium",
    },
    {
      role: "Supervising Resource",
      rate: "23",
      accreditation: "NSW Responsible Service of Alcohol",
      types: "Standard",
    },
  ];
}
