import * as moment from "moment";

export class ResourceInductionsFakeDb {
  public static inductions = [
    {
      uuid: "7428168a-e65a-4331-bc4f-9b27a4a75549",
      type: "Induction",
      roles: [],
      tags: ["Security Licence", "NSW Security Licence"],
      data: '[{"label":"Expires At","type":"label","value":"16/11/2023"},{"label":"Licence Number","type":"label","value":"000212785"},{"label":"Licensee","type":"label","value":"Neda Alimohammadloo"},{"label":"Classes","type":"label","value":"1A Unarmed Guard,1C Crowd Controller"}]',
      creation_date_time: "0001-01-01T00:00:00Z",
    },
  ];
}
