import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterResouceMember",
})
export class FilterResouceMemberPipe implements PipeTransform {
  transform(
    resourceList,
    selectedValuesRating,
    selectedValuesStatus,
    selectedValuesString,
    returnType,
    clientType
  ) {
    // console.log("resourceList: ", resourceList)
    // console.log("selectedValuesRating: ", selectedValuesRating)
    // console.log("selectedValuesStatus: ", selectedValuesStatus)
    // console.log("selectedValuesString: ", selectedValuesString)

    // const isSupplierActive:boolean = selectedValuesStatus.includes(19);
    // const isSupplierInactive:boolean = selectedValuesStatus.includes(20);
    const hasStatusValue = selectedValuesStatus?.length > 0;
    const hasRatingValues: boolean = selectedValuesRating?.length > 0;
    // const hasSupplierValues:boolean = selectedValuesSupplier.length > 0
    const hasStringValues: boolean = Array.isArray(selectedValuesString)
      ? selectedValuesString?.length > 0
      : selectedValuesString !== "";
    const selectedValuesArray = Array.isArray(selectedValuesString)
      ? selectedValuesString
      : [selectedValuesString];
    const regexPattern = new RegExp(
      selectedValuesArray
        .map((keyword) => keyword.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&"))
        .map((keyword) => `(${keyword.split(" ").join(".*")})`)
        .join(""),
      "i"
    );
    let filteredValue = resourceList.filter((resource) => {
      const ratingMatch: boolean =
        (selectedValuesRating?.includes("five") &&
          resource?.assessment?._id?.length == 0) ||
        (selectedValuesRating?.includes("five") &&
          Math.floor(resource?.assessment?.avg) == 5) ||
        (selectedValuesRating?.includes("four") &&
          Math.floor(resource?.assessment?.avg) == 4) ||
        (selectedValuesRating?.includes("three") &&
          Math.floor(resource?.assessment?.avg) == 3) ||
        (selectedValuesRating?.includes("two") &&
          Math.floor(resource?.assessment?.avg) == 2) ||
        (selectedValuesRating?.includes("one") &&
          Math.floor(resource?.assessment?.avg) == 1);
      const nameMatch: boolean = regexPattern.test(
        resource?.name?.toLowerCase().replace(/ /g, "")
      );
      // selectedValuesRating?.includes(Math.floor(resource?.assessment.avg)) ||  (selectedValuesRating?.include('five') && resource?.assessment?._id?.length==0)
      if (clientType == "member") {
        const statusMatch: boolean =
          (selectedValuesStatus?.includes(1) && resource.status == 0) ||
          (selectedValuesStatus?.includes(2) &&
            (resource.status == 1 || resource.status == 2));
        return (
          (hasStringValues ? nameMatch : true) &&
          (hasStatusValue ? statusMatch : true)
        );
      } else {
        const statusMatch: boolean = selectedValuesStatus?.includes(
          resource?.status
        );
        return (
          (hasStringValues ? nameMatch : true) &&
          (hasStatusValue ? statusMatch : true) &&
          (hasRatingValues ? ratingMatch : true)
        );
      }

      // const ratingMatch:boolean = selectedValuesRating.includes(resource?.assessment?.total+13);
      // const statusMatch:boolean = (resource?.status == 1)
      // if(isSupplierActive && isSupplierInactive){
      //   return (hasStringValues ? nameMatch : true) && (hasRatingValues ? ratingMatch : true)
      // }
      // if(isSupplierActive && !isSupplierInactive){
      //   return (hasStringValues ? nameMatch : true) && (hasRatingValues ? ratingMatch : true) && (hasStatusValue ? statusMatch :true)
      // }
      // if(isSupplierInactive && !isSupplierActive){
      //   return (hasStringValues ? nameMatch : true) && (hasRatingValues ? ratingMatch : true) && !(hasStatusValue ? statusMatch :true)
      // }
      // if(!isSupplierInactive && !isSupplierActive){
      //   return (hasStringValues ? nameMatch : true) && (hasRatingValues ? ratingMatch : true)
      // }
    });
    // if (returnType === 'totalNumber') {
    //   return filteredValue.length;
    // }
    return filteredValue;
  }
}
