import { Injectable } from "@angular/core";
import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";
import { environment } from "environments/environment";
import { VERSION } from "environments/version";
import { parse } from "node-html-parser";
import { BehaviorSubject } from "rxjs";
import { differenceInHours } from "date-fns";

export class TaskStatus {
  public static TASK_STATUS_PENDING = 1;
  public static TASK_STATUS_DECLINED = 3;
  public static TASK_STATUS_ACCEPT = 2;
  public static TASK_NOT_SHARED = 0;
}

export class ScheduleSetting {
  public static TIME_LIMIT_BEFORE_START = 2;
  public static TIME_LIMIT_AFTER_START = 4;
  public static TIME_LIMIT_AFTER_END = 4;
  public static TIME_LIMIT_INCIDENT_REPORT = 6;
}

@Injectable({
  providedIn: "root",
})
export class UtilService {
  termsInnerHtml = new BehaviorSubject("");
  privacyInnerHtml = new BehaviorSubject("");
  versionOfApp = "";

  constructor() {
    this.getPrivacy();
    this.getTerms();
  }

  getVersionOfApp() {
    const version = `${VERSION.version}`;
    this.versionOfApp = version;
  }

  getTerms() {
    (async () => {
      const response = await fetch("https://www.taskezy.com/terms/");
      if (response.status === 200) {
        const text = await response.text();
        const parsedText = parse(text);
        this.termsInnerHtml.next(
          parsedText.querySelector("section.Subscription-Agreement")?.innerHTML
        );
      }
    })();
  }

  getPrivacy() {
    (async () => {
      const response = await fetch("https://www.taskezy.com/privacy/");
      if (response.status === 200) {
        const text = await response.text();
        const parsedText = parse(text);
        this.privacyInnerHtml.next(
          parsedText.querySelector("section.Policy")?.innerHTML
        );
      }
    })();
  }

  randomString(len) {
    const r = Math.random()
      .toString(36)
      .substring(len - 1);
    return r;
  }

  b64toBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: "image/jpeg" });
  }

  getEnv() {
    if (environment.production) {
      return "prod";
    } else if (environment.stage) {
      return "stage";
    } else if (environment.test) {
      return "test";
    } else if (environment.dev) {
      return "dev";
    } else {
      return "e2e";
    }
  }

  getRole(role) {
    const roleMap = {
      0: "Resource",
      1: "Manager",
      2: "Assistant",
      3: "Administrator",
    };
    return roleMap[role] || "External Manager";
  }

  getStatus(status, isInvite, isResource) {
    const statusMap = {
      0: "Pending",
      1: "Active",
      2: "Inactive",
      3: "Banned",
      4: "Awaiting Approval",
      5: "Failed",
      6: "Verified",
      7: "Declined",
    };
    if (isInvite || isResource) {
      return statusMap[status];
    } else {
      return status === 0 ? "Active" : "Inactive";
    }
  }

  getStatusColour(status, isInvite, isResource) {
    if (isInvite || isResource) {
      switch (status) {
        case 1:
          return "bg-green-400";
        case 0:
        case 4:
        case 6:
          return "bg-orange-400";
        case 2:
        case 3:
        case 5:
        case 7:
          return "bg-red-400";
        default:
          return "bg-orange-400";
      }
    } else {
      return status === 0 ? "bg-green-400" : "bg-red-400";
    }
  }

  isEmpty(value) {
    if (value && value.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  isValidDate(dateStr) {
    if (!dateStr) {
      return false;
    }
    if (dateStr == "0001-01-01T00:00:00Z") {
      return false;
    }
    return true;
  }

  getTaskStatus(currentTask) {
    if (!currentTask) {
      return {
        class: "text-yellow-500 bg-yellow-200",
        label: "Pending",
        bgClass: "bg-yellow-500",
      };
    }

    if (
      currentTask?.resource?.release_status === TaskStatus.TASK_STATUS_PENDING
    ) {
      return {
        class: "text-yellow-500 bg-yellow-200",
        label: "Pending",
        bgClass: "bg-yellow-500",
      };
    }
    if (
      currentTask.resource?.release_status === TaskStatus.TASK_STATUS_DECLINED
    ) {
      return {
        class: "text-pink-500 bg-pink-200",
        label: "Declined",
        bgClass: "bg-pink-500",
      };
    }

    if (
      currentTask.resource?.release_status === TaskStatus.TASK_STATUS_ACCEPT
    ) {
      if (this.isEmpty(currentTask.timesheet.signoff.signature)) {
        return {
          class: "text-green-500 bg-green-200",
          label: "Completed",
          bgClass: "bg-green-500",
        };
      }

      if (!this.isEmpty(currentTask.timesheet.signon.signature)) {
        const timeDiffHour = differenceInHours(
          new Date(currentTask.start_time),
          new Date()
        );
        if (
          timeDiffHour < ScheduleSetting.TIME_LIMIT_BEFORE_START &&
          timeDiffHour > -ScheduleSetting.TIME_LIMIT_AFTER_START
        ) {
          return {
            class: "text-gray-500	bg-gray-200	",
            label: "Not Present",
            bgClass: "bg-gray-500",
          };
        }
      }

      if (this.isEmpty(currentTask.timesheet.signon.signature)) {
        if (
          !this.isValidDate(currentTask?.timesheet?.start) ||
          this.isValidDate(currentTask?.timesheet?.end)
        ) {
          return {
            class: "text-green-500 bg-green-200",
            label: "Available",
            bgClass: "bg-green-500",
          };
        }

        if (
          currentTask.timesheet.breaks[
            currentTask.timesheet.breaks.length - 1
          ]?.hasOwnProperty("duration") == true
        ) {
          return {
            class: "text-sky-500 bg-sky-200",
            label: "In Shift",
            bgClass: "bg-sky-500",
          };
        } else if (
          this.isEmpty(currentTask.timesheet.signon.signature) &&
          currentTask.timesheet.breaks[
            currentTask.timesheet.breaks.length - 1
          ]?.hasOwnProperty("duration") == false
        ) {
          return {
            class: "text-yellow-500 bg-yellow-200",
            label: "On Break",
            bgClass: "bg-yellow-500",
          };
        } else {
          return {
            class: "text-sky-500 bg-sky-200",
            label: "In Shift",
            bgClass: "bg-sky-500",
          };
        }
      }

      return {
        class: "text-green-500 bg-green-200",
        label: "Accepted",
        bgClass: "bg-green-500",
      };
    }

    return {
      class: "text-yellow-500 bg-yellow-200",
      label: "Pending",
      bgClass: "bg-yellow-500",
    };
  }

  abnValidator(): ValidatorFn {
    if (environment.e2e) {
      return (control: AbstractControl): ValidationErrors | null => null;
    }
    return (control: AbstractControl): ValidationErrors | null => {
      // prettier-ignore
      // eslint-disable-next-line
      const weights = [10,1,3,5,7,9,11,13,15,17,19];
      const cleanedABN = control.value?.replace(/\D/g, "") || "";
      let valid = false;
      if (cleanedABN.length === 11) {
        let sum = 0;
        weights.forEach((weight, ind) => {
          const digit = parseInt(cleanedABN[ind], 10) - (ind === 0 ? 1 : 0);
          sum += weight * digit;
        });
        valid = sum % 89 === 0;
      } else {
        valid = false;
      }

      return valid ? null : { invalid: true };
    };
  }
}
