import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { fuseAnimations } from "@fuse/animations";

import { AccountService } from "../../../core/services/account/account.service";
import { MobileVerificationService } from "../../../core/services/auth/mobile-verification/mobile-verification.service";
import { LoginService } from "../../../core/services/auth/login/login.service";

@Component({
  selector: "app-auth-layout",
  templateUrl: "./auth-layout.component.html",
  animations: fuseAnimations,
})
export class AuthLayoutComponent implements OnInit {
  isSendMobileCode;
  isBackDrop = false;

  @Input() name;
  @Input() status;
  @Input() hasInvites;
  @Input() noSplit = false;
  @Input() showHeader = false;
  @Input() showBack = false;
  @Input() showTitle = true;
  @Input() circles = true;
  @Input() asResource;
  @Input() headerName;
  @Output() toBack = new EventEmitter<any>();

  constructor(
    private accountService: AccountService,
    private loginService: LoginService,
    private mobileVerificationService: MobileVerificationService
  ) {}

  ngOnInit(): void {
    this.mobileVerificationService.isSendCode.subscribe((res) => {
      this.isSendMobileCode = res;
    });
    this.loginService.backDrop?.subscribe((res) => {
      this.isBackDrop = res;
    });
  }

  toBackCall() {
    if (this.status === "verify-mobile") {
      this.mobileVerificationService.isSendCode = false;
    } else {
      this.toBack.emit();
    }
  }
}
