import * as moment from "moment";

export class ConnectedVenuesFakeDb {
  public static venues = [
    {
      uuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      client: {
        name: "Robert's Super Security Firm Pty Ltd",
        uuid: "12341234134",
      },
      venue: {
        managers: [
          {
            address: "23 Norton Plaza, Leichardt, NSW, 2040",
            email: "mike@gmail.com",
            name: "Mike Miriston",
            phone: "+61437794659",
            uuid: "6273g2y3ye23egb2e",
          },
        ],
        accreditation_requirements: [
          {
            description:
              "A valid NSW security licence is required to work at this venue.",
            name: "NSW Security Licence",
            source: "https://www.example.com/nsw-security-licence.pdf",
            template_uuid: "123456789",
            timing_requirement: "Before Shift Start",
            type: "Security",
          },
        ],
        address: {
          geo_location: {
            coordinates: [
              151.157,
              -33.884,
            ],
            type: "Point",
          },
          "google-call":
            "https://maps.googleapis.com/maps/api/place/details/json?placeid=xyz",
          "google-place-id": "ChIJN1t_tDeuEmsRUsoyG83frY4",
          street_location: "23 Norton Plaza, Leichhardt, NSW, 2040",
        },
        comment:
          "Ensure compliance with security accreditation before shift start.",
        contract_review_date: "2023-01-01",
        contract_start_date: "2019-01-01",
        contract_term: "2 Years",
        documents: [
          {
            date_added: "2022-06-15",
            date_modified: "2023-04-10",
            description: "Signed contract agreement",
            name: "Security Service Agreement",
            private: true,
            tag: "contract",
            uuid: "abc123-def456-ghi789",
          },
        ],
        executing_parties: "John Smith, CEO; Peter Holiday, Manager",
        licence_name: "Security Operations Licence",
        licence_number: "NSW-SEC-987654",
        logo: "https://www.example.com/venue-logo.png",
        name: "Norton Plaza Security",
        roles: [
          {
            credentials: [
              {
                data: '{"template_uuid":"636086a9d9dbfb0537f92125","type":"Identification","name":"Security ID","description":"Passport or Driver\'s Licence","timing_requirement":"Before commencing the Shift","source":"https://www.example.com/security-id.pdf","selected":true}',
                roles: [0],
                tags: [
                  "tag1",
                  "tag2",
                ],
                template: "1234-1234-1234",
                type: "Induction",
                uuid: "1234-1234-1234",
              },
            ],
            resource_rate_id: "d8e28207-ad9f-4620-906c-117ef0ed4938",
            description: "Responsible for maintaining security at the venue.",
            item_code: "#123-321",
            name: "Security Guard",
            rates: [
              {
                item_code: "#123 456",
                name: "Standard",
                value: 10,
              },
            ],
            uuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          },
        ],
        stations: [
          {
            name: "Main Entrance Checkpoint",
          },
        ],
        type: "Private",
        uuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        sign_on_distance: 100,
        resource_rates: [
          {
            description: "Hourly rates for security personnel.",
            name: "Security Service Rates",
            rates: [
              {
                item_code: "STD001",
                name: "Standard",
                value: 15,
              },
              {
                item_code: "PREM002",
                name: "Premium",
                value: 35,
              },
              {
                item_code: "DISC003",
                name: "Discount",
                value: 45,
              },
            ],
            uuid: "d8e28207-ad9f-4620-906c-117ef0ed4938",
          },
        ],
      },
    },
  ];
}
