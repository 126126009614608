import * as moment from "moment";

export class AuditLogFakeDb {
  public static logs = [
    {
      id: "34563476",
      email: "admin@gmail.com",
      eventType: "Updated",
      auditableId: "439",
      auditableType: "Accreditation",
      url: "Console Script",
      ipAddress: "N/A",
      userAgent: "N/A",
      dateOfEvent: moment("20201028").format(),
      startDate: moment("20190922").format(),
      endDate: moment("20190315").format(),
      classes: [
        {
          class: "ME",
          status: "current",
          endDate: "20220214",
          startDate: "2012-10-31",
          description: "Provide 50 or more persons",
        },
      ],
    },
    {
      id: "569jmt5663rg",
      email: "yulia@gmail.com",
      eventType: "Updated",
      auditableId: "3453",
      auditableType: "Accreditation",
      url: "Console Script",
      ipAddress: "N/A",
      userAgent: "N/A",
      dateOfEvent: moment("20200619").format(),
      startDate: moment("20190922").format(),
      endDate: moment("20190315").format(),
      classes: [
        {
          class: "ME",
          status: "current",
          endDate: "20220214",
          startDate: "2012-10-31",
          description: "Provide 50 or more persons",
        },
      ],
    },
    {
      id: "dth767tyrr6",
      email: "anna@gmail.com",
      eventType: "Updated",
      auditableId: "865",
      auditableType: "Accreditation",
      url: "Console Script",
      ipAddress: "N/A",
      userAgent: "N/A",
      dateOfEvent: moment("20200605").format(),
      startDate: moment("20190922").format(),
      endDate: moment("20190315").format(),
      classes: [
        {
          class: "ME",
          status: "current",
          endDate: "20220214",
          startDate: "2012-10-31",
          description: "Provide 50 or more persons",
        },
      ],
    },
    {
      id: "fghf6767564rs4w5",
      email: "robert@gmail.com",
      eventType: "Updated",
      auditableId: "345",
      auditableType: "Accreditation",
      url: "Console Script",
      ipAddress: "N/A",
      userAgent: "N/A",
      dateOfEvent: moment("20200916").format(),
      startDate: moment("20190922").format(),
      endDate: moment("20190315").format(),
      classes: [
        {
          class: "ME",
          status: "current",
          endDate: "20220214",
          startDate: "2012-10-31",
          description: "Provide 50 or more persons",
        },
      ],
    },
    {
      id: "uiky98565fvgs",
      email: "nickolay@gmail.com",
      eventType: "Updated",
      auditableId: "888",
      auditableType: "Accreditation",
      url: "Console Script",
      ipAddress: "N/A",
      userAgent: "N/A",
      dateOfEvent: moment("20200911").format(),
      startDate: moment("20190922").format(),
      endDate: moment("20190315").format(),
      classes: [
        {
          class: "ME",
          status: "current",
          endDate: "20220214",
          startDate: "2012-10-31",
          description: "Provide 50 or more persons",
        },
      ],
    },
    {
      id: "45fsdtevxf5456ed",
      eventType: "Updated",
      email: "managment@gmail.com",
      auditableId: "342",
      auditableType: "Accreditation",
      url: "Console Script",
      ipAddress: "N/A",
      userAgent: "N/A",
      dateOfEvent: moment("20200623").format(),
      startDate: moment("20190922").format(),
      endDate: moment("20190315").format(),
      classes: [
        {
          class: "ME",
          status: "current",
          endDate: "20220214",
          startDate: "2012-10-31",
          description: "Provide 50 or more persons",
        },
      ],
    },
    {
      id: "890890763fdg343",
      email: "office@gmail.com",
      eventType: "Updated",
      auditableId: "422",
      auditableType: "Accreditation",
      url: "Console Script",
      ipAddress: "N/A",
      userAgent: "N/A",
      dateOfEvent: moment("20200724").format(),
      startDate: moment("20190922").format(),
      endDate: moment("20190315").format(),
      classes: [
        {
          class: "ME",
          status: "current",
          endDate: "20220214",
          startDate: "2012-10-31",
          description: "Provide 50 or more persons",
        },
      ],
    },
    {
      id: "rtuy7674hdgh",
      email: "daria@gmail.com",
      eventType: "Updated",
      auditableId: "123",
      auditableType: "Accreditation",
      url: "Console Script",
      ipAddress: "N/A",
      userAgent: "N/A",
      dateOfEvent: moment("20200712").format(),
      startDate: moment("20190922").format(),
      endDate: moment("20190315").format(),
      classes: [
        {
          class: "ME",
          status: "current",
          endDate: "20220214",
          startDate: "2012-10-31",
          description: "Provide 50 or more persons",
        },
      ],
    },
    {
      id: "yu7857ed5grf",
      email: "patricia@gmail.com",
      eventType: "Updated",
      auditableId: "987",
      auditableType: "Accreditation",
      url: "Console Script",
      ipAddress: "N/A",
      userAgent: "N/A",
      dateOfEvent: moment("20200810").format(),
      startDate: moment("20190922").format(),
      endDate: moment("20190315").format(),
      classes: [
        {
          class: "ME",
          status: "current",
          endDate: "20220214",
          startDate: "2012-10-31",
          description: "Provide 50 or more persons",
        },
      ],
    },
    {
      id: "hdt56725hgfsass",
      email: "oleg@gmail.com",
      eventType: "Updated",
      auditableId: "456",
      auditableType: "Accreditation",
      url: "Console Script",
      ipAddress: "N/A",
      userAgent: "N/A",
      dateOfEvent: moment("20201030").format(),
      startDate: moment("20190922").format(),
      endDate: moment("20190315").format(),
      classes: [
        {
          class: "ME",
          status: "current",
          endDate: "20220214",
          startDate: "2012-10-31",
          description: "Provide 50 or more persons",
        },
      ],
    },
    {
      id: "s55fd5fh7g",
      email: "corporation@gmail.com",
      eventType: "Updated",
      auditableId: "326",
      auditableType: "Accreditation",
      url: "Console Script",
      ipAddress: "N/A",
      userAgent: "N/A",
      dateOfEvent: moment("20200903").format(),
      startDate: moment("20190922").format(),
      endDate: moment("20190315").format(),
      classes: [
        {
          class: "ME",
          status: "current",
          endDate: "20220214",
          startDate: "2012-10-31",
          description: "Provide 50 or more persons",
        },
      ],
    },
  ];
}
