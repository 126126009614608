export class VenueResourcesFakeDb {
  public static resources = [
    {
      uuid: "h3454hdr32738wewf",
      name: "Lorem ipsum dolor sit amet 1",
      supplier: "dummy text 1",
      listingType: "White",
    },
    {
      uuid: "ty565yrser673432rgr",
      name: "Lorem ipsum dolor sit amet 2",
      supplier: "dummy text 2",
      listingType: "Black",
    },
    {
      uuid: "gfhujty76566453dsr",
      name: "Lorem ipsum dolor sit amet 3",
      supplier: "dummy text 3",
      listingType: "Black",
    },
    {
      uuid: "w4454877675rew43",
      name: "Lorem ipsum dolor sit amet 4",
      supplier: "dummy text 4",
      listingType: "Black",
    },
    {
      uuid: "et45t467674545rtgr",
      name: "Lorem ipsum dolor sit amet 5",
      supplier: "dummy text 5",
      listingType: "White",
    },
  ];
}
