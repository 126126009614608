import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterVenue",
})
export class FilterVenuePipe implements PipeTransform {
  transform(
    resourceList,
    selectedTypes,
    selectedSearchesByClient,
    selectedValuesString,
    selectedStates
  ) {
    // console.log("selectedStates: ", selectedStates)
    // console.log("resourceList: ", resourceList)
    // console.log("selectedTypes: ", selectedTypes)
    // console.log("selectedSearchesByClient: ", selectedSearchesByClient)
    // const isSupplierActive:boolean = selectedValuesStatus.includes(19);
    // const isSupplierInactive:boolean = selectedValuesStatus.includes(20);
    // const hasStatusValue = selectedValuesStatus.length > 0;
    // const hasRatingValues:boolean = selectedValuesRating.length > 0;
    // const hasSupplierValues:boolean = selectedValuesSupplier.length > 0
    // if(selectedStates.include(13)){
    //   console.log("NSW")
    // }

    let isPublic: boolean = selectedTypes.includes("public");
    let isPrivate: boolean = selectedTypes.includes("private");
    const hasStateValue: boolean = selectedStates.length > 0;
    const hasSelectedTypes: boolean = selectedTypes.length > 0;
    const hasSelectedClients: boolean = selectedSearchesByClient.length > 0;
    const hasStringValues: boolean = selectedValuesString?.length > 0;
    const selectedValuesArray = Array.isArray(selectedValuesString)
      ? selectedValuesString
      : [selectedValuesString];

    const regexPattern = new RegExp(
      selectedValuesArray
        .map((keyword) =>
          keyword?.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
        )
        .map((keyword) => `(${keyword?.split(" ").join(".*")})`)
        .join(""),
      "i"
    );
    let filteredValue = resourceList.filter((venue) => {
      // console.log("lets check venue: ", venue)
      // console.log("lets check venue.state: ", venue.state)
      let typeMatchPublic: boolean;
      let typeMatchPrivate: boolean;
      if (venue.type === "Public" && isPublic) {
        typeMatchPublic = true;
      }
      if (venue.type === "Private" && isPrivate) {
        typeMatchPrivate = true;
      }
      const selectedStateMatch: boolean = selectedStates?.includes(
        venue?.state
      );
      const nameMatch: boolean = regexPattern.test(
        venue?.name?.toLowerCase().replace(/ /g, "")
      );
      const nameClientMatch: boolean = regexPattern.test(
        venue?.client?.name?.toLowerCase().replace(/ /g, "")
      );
      const nameAddressMatch: boolean = regexPattern.test(
        venue?.address?.street_location?.toLowerCase().replace(/ /g, "")
      );
      const clientNameMatch: boolean = selectedSearchesByClient?.includes(
        venue?.client?.name
      );
      // const typesMatch:boolean = selectedTypes.includes(venue?.type)
      return (
        (hasSelectedClients ? clientNameMatch : true) &&
        (hasSelectedTypes ? typeMatchPublic || typeMatchPrivate : true) &&
        (hasStringValues
          ? nameMatch || nameClientMatch || nameAddressMatch
          : true) &&
        (hasStateValue ? selectedStateMatch : true)
      );

      //  (isPublic ? typeMatchPublic : true) && (isPrivate ? typeMatchPrivate : true)
      // const ratingMatch:boolean = selectedValuesRating.includes(resource?.assessment?.total+13);
      // const statusMatch:boolean = (resource?.status == 1)
      // if(isSupplierActive && isSupplierInactive){
      //   return (hasStringValues ? nameMatch : true) && (hasRatingValues ? ratingMatch : true)
      // }
      // if(isSupplierActive && !isSupplierInactive){
      //   return (hasStringValues ? nameMatch : true) && (hasRatingValues ? ratingMatch : true) && (hasStatusValue ? statusMatch :true)
      // }
      // if(isSupplierInactive && !isSupplierActive){
      //   return (hasStringValues ? nameMatch : true) && (hasRatingValues ? ratingMatch : true) && !(hasStatusValue ? statusMatch :true)
      // }
      // if(!isSupplierInactive && !isSupplierActive){
      //   return (hasStringValues ? nameMatch : true) && (hasRatingValues ? ratingMatch : true)
      // }
    });
    return filteredValue;
  }
}
