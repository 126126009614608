export class ResourceRatesFakeDb {
  public static rates = [
    {
      id: "gtbrt3424532e343er4",
      venue: "Opera Bar",
      week: "15 hours 47 minutes 20$ per 18 cents/hour",
      month: "15 hours 47 minutes 20$ per 18 cents/hour",
      year: "15 hours 47 minutes 20$ per 18 cents/hour",
    },
  ];
}
