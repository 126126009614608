import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { PrimaryAccountMenuComponent } from "./primary-account-menu.component";
import { SharedModule } from "app/shared/shared.module";

@NgModule({
  declarations: [PrimaryAccountMenuComponent],
  imports: [
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    SharedModule,
  ],
  exports: [PrimaryAccountMenuComponent],
})
export class PrimaryAccountMenuModule {}
