export class SuppliersResources {
  public static suppliers = [
    {
      id: 1,
      resource: {
        user_id: "123456",
        name: "Trevino Marvel1",
      },
      supplier: {
        uuid: "345456",
        name: "Ketty Marvel",
      },
    },
    {
      id: 2,
      resource: {
        user_id: "123456",
        name: "Trevino Marvel2",
      },
      supplier: {
        uuid: "345456",
        name: "Ketty Marvel",
      },
    },
    {
      id: 3,
      resource: {
        user_id: "123456",
        name: "Trevino Marvel3",
      },
      supplier: {
        uuid: "345456",
        name: "Ketty Marvel",
      },
    },
    {
      id: 4,
      resource: {
        user_id: "123456",
        name: "Trevino Marvel4",
      },
      supplier: {
        uuid: "345456",
        name: "Ketty Marvel",
      },
    },
  ];
}
