export class AssessmentsFakeDb {
  public static assessments = [
    {
      uuid: "2342323532352",
      name: "Denny Ramos",
      type: "Client",
      description: "Assessment 1 Description",
      rating: 4,
    },
    {
      uuid: "234223282392",
      name: "Sebastian Conley",
      type: "Supplier",
      description: "Assessment 2 Description",
      rating: 5,
    },
    {
      uuid: "gt657865tuetyft7",
      name: "Edward Martin",
      type: "Supervising Resource",
      description: "Assessment 3 Description",
      rating: 5,
    },
  ];
}
