import * as moment from "moment";
const startOfWeek = moment().startOf("week");
export class RosterTasksFakeDb {
  public static tasks = [
    {
      id: "1289",
      uuid: "1289",
      account_uuid: "1289128912891289",
      release_status: 1,
      release_history: [
        {
          uuid: "d949b1c8-cb2e-4623-b7d1-67e68a644da8",
          release_date: "2022-09-06T03:27:44.97Z",
          released_by_member: {
            uuid: "d5cda1f0-f8b0-48ab-89cb-08a85905a289",
            email: "nicholas.tsaoucis@gravityfusion.com",
            phone: "+61431700596",
          },
          release_status: 1,
          notifications: [],
          system_note: "Shift released to client",
        },
      ],
      datetime: moment(startOfWeek).add(2, "days").format(),
      duration: 210,
      notes: [
        {
          author_id: "5edebhh5dfis8",
          author: "Daria D",
          datetime: moment().format(),
          note: "",
        },
      ],
      changelog: [
        {
          avatar: "",
          date: moment("2021-06-10").format(),
          status: "created",
          user: "Selvador Kris",
        },
      ],
      venue: {
        uuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        name: "Opera Pub",
        address: {
          street_location:
            "Rua Quinta dos Morgados YY, Barreiro e Lavradio, Portugal",
          geolocation: {
            type: "Point",
            coordinates: [
              -105.7463,
              38.797222,
            ],
          },
          "google-call": "",
          "google-place-id": "",
        },
        roles: [
          {
            credentials: [
              {
                data: '{"template_uuid":"636086a9d9dbfb0537f92125","type":"Identification","name":"place accred finance","description":"Passport","timing_requirement":"Before commence the Shift","source":"https://www.example.com/licence.pdf","selected":true}',
                roles: [0],
                tags: [
                  '["tag1"',
                  '"tag2"]',
                ],
                template: "1234-1234-1234",
                type: "Induction",
                uuid: "1234-1234-1234",
              },
            ],
            resource_rate_id: "d8e28207-ad9f-4620-906c-117ef0ed4938",
            description: "Guard description",
            item_code: "#123-321",
            name: "Guard",
            rates: [
              {
                item_code: "#123 456",
                name: "Standard",
                value: 10,
              },
            ],
            uuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          },
        ],
        stations: [],
      },
      client_uuid: "98746563111",
      client_status: 0,
      shift_number: "",
      tasks: [
        {
          assessments: [],
          start_time: moment(startOfWeek).add(2, "days").format(),
          duration: 210,
          role: {
            uuid: "e359bcee-26b4-45c2-b026-f917dd4d5672",
            name: "Guard",
            item_code: "",
            description: "guardy guard",
            rates: [
              {
                name: "Standard",
                value: 12.5,
                item_code: "3000",
              },
              {
                name: "Premium",
                value: 25.5,
                item_code: "3001",
              },
              {
                name: "Discount",
                value: 45.5,
                item_code: "3002",
              },
            ],
            credentials: [],
          },
          supervisor: false,
          supplier: {
            uuid: "12341234134",
            name: "Jack Allon",
            release_status: 1,
          },
          resource: {
            uuid: "123456",
            name: "Trevino Marvel1",
            release_status: 2,
          },
          notes: [
            {
              author_id: "gf34567ytydf56y",
              author: "Daria D",
              datetime: moment().format(),
              note: "",
            },
            {
              uuid: "09b8d960-c7f1-46b0-aa59-a76f5c5948c8",
              author_id: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              author: "Fred 113 Test",
              datetime: "21 May 2024 12:30",
              note: "Alteration suggested by Fred 113 Test",
              hidden: false,
            },
            {
              uuid: "aeb59f12-b9b1-4b6b-8d64-308ddb382625",
              author_id: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              author: "Fred 113 Test",
              datetime: "21 May 2024 13:30",
              note: "testing, shift was created ~ 2: 40 ish but the resource can be set to have started at midnight??? by Fred 113 Test",
              hidden: false,
            },
            {
              uuid: "68120443-062f-40dc-b7f9-3bcd59189fdd",
              author_id: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              author: "Fred 113 Test",
              datetime: "21 May 2024 14:30",
              note: "Splits replaced by Fred 113 Test",
              hidden: false,
            },
          ],
          timesheet: {
            breaks: [
              {
                datetime: moment()
                  .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
                  .add(1, "hours"),
                duration: 6,
                geolocation: { type: "", coordinates: null },
                signature: "",
                userId: "c74c3a11-8399-413a-89d0-40492e8531e1",
              },
            ],
            signoff: {
              datetime: moment().add(5, "hours").format(),
              geolocation: { type: "", coordinates: null },
              signature:
                '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 125 80" width="125" height="80"><path d="M 80.000,109.000 C 116.028,97.120 116.500,100.000 153.000,91.000" stroke-width="6.955" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 153.000,91.000 C 212.398,76.701 188.528,85.120 225.000,85.000" stroke-width="4.286" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 225.000,85.000 C 212.279,105.493 232.898,90.201 194.000,118.000" stroke-width="5.913" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 194.000,118.000 C 160.452,128.984 174.279,131.493 149.000,137.000" stroke-width="4.890" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 149.000,137.000 C 156.510,139.459 145.452,139.484 164.000,139.000" stroke-width="6.954" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 164.000,139.000 C 180.923,135.447 181.010,137.959 198.000,134.000" stroke-width="5.769" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 198.000,134.000 C 210.500,134.000 210.423,132.947 223.000,134.000" stroke-width="7.199" stroke="black" fill="none" stroke-linecap="round"></path></svg>',
              userId: "c74c3a11-8399-413a-89d0-40492e8531e1",
            },
            signon: {
              datetime: moment().add(2, "hours").format(),
              geolocation: { type: "", coordinates: null },
              signature:
                '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 125 80" width="125" height="80"><path d="M 105.000,127.000 C 110.166,125.354 109.500,124.500 114.000,122.000" stroke-width="8.020" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 114.000,122.000 C 119.300,109.413 122.166,114.854 129.000,106.000" stroke-width="6.698" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 129.000,106.000 C 148.476,103.521 146.300,99.913 168.000,103.000" stroke-width="5.452" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 168.000,103.000 C 192.139,96.701 185.976,102.521 204.000,104.000" stroke-width="5.296" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 204.000,104.000 C 212.056,117.678 211.639,108.701 207.000,127.000" stroke-width="6.567" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 207.000,127.000 C 190.633,146.363 196.056,147.178 172.000,163.000" stroke-width="4.999" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 172.000,163.000 C 161.283,169.703 162.633,171.363 151.000,177.000" stroke-width="5.648" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 151.000,177.000 C 143.521,179.957 145.783,180.703 141.000,185.000" stroke-width="6.631" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 141.000,185.000 C 138.600,193.031 139.021,188.957 142.000,195.000" stroke-width="7.228" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 142.000,195.000 C 150.991,195.830 148.100,198.531 160.000,196.000" stroke-width="6.788" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 160.000,196.000 C 178.118,199.044 177.491,196.330 195.000,196.000" stroke-width="5.668" stroke="black" fill="none" stroke-linecap="round"></path></svg>',
              userId: "c74c3a11-8399-413a-89d0-40492e8531e1",
            },
            result: 0,
            splits: [],
            additional_times: [],
            start: moment().add(1, "hours").set("minute", 30).format(),
            end: moment().add(2, "hours").set("minute", 30).format(),
            challenges: [
              {
                user: {
                  uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
                  name: "Fred Supervisor",
                  role: "Supervisor",
                },
                signon: {
                  time: "0001-01-01T00:00:00Z",
                  accepted: false,
                },
                signoff: {
                  time: "0001-01-01T00:00:00Z",
                  accepted: false,
                },
                starttime: {
                  time: moment().add(3, "hours").set("minute", 30).format(),
                  accepted: false,
                },
                endtime: {
                  time: moment().add(4, "hours").set("minute", 30).format(),
                  accepted: false,
                },
                break: {
                  duration: 12,
                  accepted: false,
                },
              },
              {
                user: {
                  uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
                  name: "Fred Venue Manager",
                  role: "Venue Manager",
                },
                signon: {
                  time: "0001-01-01T00:00:00Z",
                  accepted: false,
                },
                signoff: {
                  time: "0001-01-01T00:00:00Z",
                  accepted: false,
                },
                starttime: {
                  time: moment().add(5, "hours").set("minute", 30).format(),
                  accepted: false,
                },
                endtime: {
                  time: moment().add(6, "hours").set("minute", 30).format(),
                  accepted: false,
                },
                break: {
                  duration: 18,
                  accepted: false,
                },
              },
              {
                user: {
                  uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
                  name: "Fred Supervisor1",
                  role: "Supervisor",
                },
                signon: {
                  time: "0001-01-01T00:00:00Z",
                  accepted: false,
                },
                signoff: {
                  time: "0001-01-01T00:00:00Z",
                  accepted: false,
                },
                starttime: {
                  time: moment().add(3, "hours").set("minute", 31).format(),
                  accepted: false,
                },
                endtime: {
                  time: moment().add(4, "hours").set("minute", 31).format(),
                  accepted: false,
                },
                break: {
                  duration: 12,
                  accepted: false,
                },
              },
              {
                user: {
                  uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
                  name: "Fred Venue Manager1",
                  role: "Venue Manager",
                },
                signon: {
                  time: "0001-01-01T00:00:00Z",
                  accepted: false,
                },
                signoff: {
                  time: "0001-01-01T00:00:00Z",
                  accepted: false,
                },
                starttime: {
                  time: moment().add(5, "hours").set("minute", 32).format(),
                  accepted: false,
                },
                endtime: {
                  time: moment().add(6, "hours").set("minute", 32).format(),
                  accepted: false,
                },
                break: {
                  duration: 18,
                  accepted: false,
                },
              },
              {
                user: {
                  uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
                  name: "Fred Supervisor2",
                  role: "Supervisor",
                },
                signon: {
                  time: "0001-01-01T00:00:00Z",
                  accepted: false,
                },
                signoff: {
                  time: "0001-01-01T00:00:00Z",
                  accepted: false,
                },
                starttime: {
                  time: moment().add(3, "hours").set("minute", 33).format(),
                  accepted: false,
                },
                endtime: {
                  time: moment().add(4, "hours").set("minute", 33).format(),
                  accepted: false,
                },
                break: {
                  duration: 12,
                  accepted: false,
                },
              },
              {
                user: {
                  uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
                  name: "Fred Venue Manager2",
                  role: "Venue Manager",
                },
                signon: {
                  time: "0001-01-01T00:00:00Z",
                  accepted: false,
                },
                signoff: {
                  time: "0001-01-01T00:00:00Z",
                  accepted: false,
                },
                starttime: {
                  time: moment().add(5, "hours").set("minute", 34).format(),
                  accepted: false,
                },
                endtime: {
                  time: moment().add(6, "hours").set("minute", 34).format(),
                  accepted: false,
                },
                break: {
                  duration: 18,
                  accepted: false,
                },
              },
            ],
          },
        },
      ],
    },
    {
      id: "1234",
      uuid: "1234",
      account_uuid: "12341234134",
      release_status: 1,
      release_history: [
        {
          uuid: "d949b1c8-cb2e-4623-b7d1-67e68a644da8",
          release_date: "2022-09-06T03:27:44.97Z",
          released_by_member: {
            uuid: "d5cda1f0-f8b0-48ab-89cb-08a85905a289",
            email: "nicholas.tsaoucis@gravityfusion.com",
            phone: "+61431700596",
          },
          release_status: 1,
          notifications: [],
          system_note: "Shift released to client",
        },
      ],
      datetime: moment().subtract(10, "minutes").format(),
      duration: 210,
      notes: [
        {
          author_id: "5edebhh5dfis8",
          author: "Daria D",
          datetime: moment().format(),
          note: "",
        },
      ],
      changelog: [
        {
          avatar: "",
          date: moment("2021-06-11").format(),
          status: "created",
          user: "Selvador Kris",
        },
      ],
      venue: {
        uuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        name: "Irish Pub",
        address: {
          street_location:
            "Rua Quinta dos Morgados YY, Barreiro e Lavradio, Portugal",
          geolocation: {
            type: "Point",
            coordinates: [
              -105.7463,
              38.797222,
            ],
          },
          "google-call": "",
          "google-place-id": "",
        },
        roles: [],
        stations: [],
      },
      client_uuid: "98746563111",
      client_status: 0,
      shift_number: "",
      tasks: [
        {
          assessments: [],
          start_time: moment().subtract(10, "minutes").format(),
          duration: 210,
          role: {
            uuid: "e359bcee-26b4-45c2-b026-f917dd4d5672",
            name: "Guard",
            item_code: "",
            description: "guardy guard",
            rates: [
              {
                name: "Standard",
                value: 12.5,
                item_code: "3000",
              },
              {
                name: "Premium",
                value: 25.5,
                item_code: "3001",
              },
              {
                name: "Discount",
                value: 45.5,
                item_code: "3002",
              },
            ],
            credentials: [],
          },
          supervisor: false,
          supplier: {
            uuid: "7890789078907890",
            name: "Jack Allon",
            release_status: 1,
          },
          resource: {
            uuid: "123456",
            name: "Trevino Marvel2",
            release_status: 2,
          },
          notes: [
            {
              author_id: "gf34567ytydf56y",
              author: "Daria D",
              datetime: moment().format(),
              note: "",
            },
          ],
          timesheet: {
            breaks: [
              {
                datetime: moment().add(1, "hours"),
                duration: 30,
                geolocation: { type: "", coordinates: null },
                signature: "",
                userId: "c74c3a11-8399-413a-89d0-40492e8531e1",
              },
            ],
            signoff: {
              datetime: moment().add(5, "hours").format(),
              geolocation: { type: "", coordinates: null },
              signature:
                '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 125 80" width="125" height="80"><path d="M 80.000,109.000 C 116.028,97.120 116.500,100.000 153.000,91.000" stroke-width="6.955" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 153.000,91.000 C 212.398,76.701 188.528,85.120 225.000,85.000" stroke-width="4.286" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 225.000,85.000 C 212.279,105.493 232.898,90.201 194.000,118.000" stroke-width="5.913" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 194.000,118.000 C 160.452,128.984 174.279,131.493 149.000,137.000" stroke-width="4.890" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 149.000,137.000 C 156.510,139.459 145.452,139.484 164.000,139.000" stroke-width="6.954" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 164.000,139.000 C 180.923,135.447 181.010,137.959 198.000,134.000" stroke-width="5.769" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 198.000,134.000 C 210.500,134.000 210.423,132.947 223.000,134.000" stroke-width="7.199" stroke="black" fill="none" stroke-linecap="round"></path></svg>',
              userId: "c74c3a11-8399-413a-89d0-40492e8531e1",
            },
            signon: {
              datetime: moment().add(2, "hours").format(),
              geolocation: { type: "", coordinates: null },
              signature:
                '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 125 80" width="125" height="80"><path d="M 105.000,127.000 C 110.166,125.354 109.500,124.500 114.000,122.000" stroke-width="8.020" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 114.000,122.000 C 119.300,109.413 122.166,114.854 129.000,106.000" stroke-width="6.698" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 129.000,106.000 C 148.476,103.521 146.300,99.913 168.000,103.000" stroke-width="5.452" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 168.000,103.000 C 192.139,96.701 185.976,102.521 204.000,104.000" stroke-width="5.296" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 204.000,104.000 C 212.056,117.678 211.639,108.701 207.000,127.000" stroke-width="6.567" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 207.000,127.000 C 190.633,146.363 196.056,147.178 172.000,163.000" stroke-width="4.999" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 172.000,163.000 C 161.283,169.703 162.633,171.363 151.000,177.000" stroke-width="5.648" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 151.000,177.000 C 143.521,179.957 145.783,180.703 141.000,185.000" stroke-width="6.631" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 141.000,185.000 C 138.600,193.031 139.021,188.957 142.000,195.000" stroke-width="7.228" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 142.000,195.000 C 150.991,195.830 148.100,198.531 160.000,196.000" stroke-width="6.788" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 160.000,196.000 C 178.118,199.044 177.491,196.330 195.000,196.000" stroke-width="5.668" stroke="black" fill="none" stroke-linecap="round"></path></svg>',
              userId: "c74c3a11-8399-413a-89d0-40492e8531e1",
            },
            result: 0,
            splits: [],
            additional_times: [],
          },
        },
      ],
    },
    // {
    //   id: "3458753",
    //   uuid: "3458753",
    //   account_uuid: "1289128912891289",
    //   release_status: 1,
    // release_history: [
    //   {
    //     uuid: 'd949b1c8-cb2e-4623-b7d1-67e68a644da8',
    //     release_date: '2022-09-06T03:27:44.97Z',
    //     released_by_member: {
    //       uuid: 'd5cda1f0-f8b0-48ab-89cb-08a85905a289',
    //       email: 'nicholas.tsaoucis@gravityfusion.com',
    //       phone: '+61431700596'
    //     },
    //     release_status: 1,
    //     notifications: [],
    //     system_note: 'Shift released to client'
    //   }
    // ],
    //   datetime: moment().subtract(6, 'days').format(),
    //   duration: 210,
    //   notes: [
    //     {
    //       author_id: '5edebhh5dfis8',
    //       author: 'Daria D',
    //       datetime: moment().format(),
    //       note: ''
    //     }
    //   ],
    //   changelog: [
    //     {
    //       avatar: '',
    //       date: moment('2021-06-18').format(),
    //       status: 'created',
    //       user: 'Jack Allon'
    //     }
    //   ],
    //   venue: {
    //     name: 'Opera Bar',
    // address: {
    //   street_location: 'Rua Quinta dos Morgados YY, Barreiro e Lavradio, Portugal',
    //   geolocation: {
    //     type: 'Point',
    //     coordinates: [-105.7463, 38.797222]
    //   },
    //   'google-call': "",
    //   'google-place-id': ""
    // },
    // roles: [],
    // stations: []
    //   },
    //   client_uuid: "345456",
    // shift_number: "",
    // tasks: [
    //   {
    //     assessments: [],
    //   datetime: moment().subtract(6, 'days').format(),
    //   duration: 210,
    //     role: {
    //       "uuid": "e359bcee-26b4-45c2-b026-f917dd4d5672",
    //       "name": "guard 2",
    //       "item_code": "",
    //       "description": "guardy guard",
    //       "rates": [
    //           {
    //               "name": "Standard",
    //               "value": 12.5
    //           },
    //           {
    //               "name": "Premium",
    //               "value": 25.5
    //           },
    //           {
    //               "name": "Discount",
    //               "value": 45.5
    //           }
    //       ],
    //       "credentials": []
    //   },
    //     supervisor: false,
    //     supplier: {
    //       uuid: '7890789078907890',
    //       name: 'Jack Allon',
    //       release_status: 1
    //     },
    //     resource: {
    //       uuid: '123456',
    //       name: 'Trevino Marvel',
    //       release_status: 0
    //     },
    //     timesheet: {
    //       breaks: [
    //         {
    //           datetime: moment().set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).add(1, 'hours'),
    //           duration: 3,
    //           geolocation: { type: '', coordinates: null },
    //           signature: '',
    //           userId: 'c74c3a11-8399-413a-89d0-40492e8531e1'
    //         }
    //       ],
    //       signoff: {
    //         datetime: moment().add(5, 'hours').format(),
    //         geolocation: { type: '', coordinates: null },
    //         signature:
    //           '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 125 80" width="125" height="80"><path d="M 80.000,109.000 C 116.028,97.120 116.500,100.000 153.000,91.000" stroke-width="6.955" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 153.000,91.000 C 212.398,76.701 188.528,85.120 225.000,85.000" stroke-width="4.286" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 225.000,85.000 C 212.279,105.493 232.898,90.201 194.000,118.000" stroke-width="5.913" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 194.000,118.000 C 160.452,128.984 174.279,131.493 149.000,137.000" stroke-width="4.890" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 149.000,137.000 C 156.510,139.459 145.452,139.484 164.000,139.000" stroke-width="6.954" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 164.000,139.000 C 180.923,135.447 181.010,137.959 198.000,134.000" stroke-width="5.769" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 198.000,134.000 C 210.500,134.000 210.423,132.947 223.000,134.000" stroke-width="7.199" stroke="black" fill="none" stroke-linecap="round"></path></svg>',
    //         userId: 'c74c3a11-8399-413a-89d0-40492e8531e1'
    //       },
    //       signon: {
    //         datetime: moment().add(2, 'hours').format(),
    //         geolocation: { type: '', coordinates: null },
    //         signature:
    //           '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 125 80" width="125" height="80"><path d="M 105.000,127.000 C 110.166,125.354 109.500,124.500 114.000,122.000" stroke-width="8.020" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 114.000,122.000 C 119.300,109.413 122.166,114.854 129.000,106.000" stroke-width="6.698" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 129.000,106.000 C 148.476,103.521 146.300,99.913 168.000,103.000" stroke-width="5.452" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 168.000,103.000 C 192.139,96.701 185.976,102.521 204.000,104.000" stroke-width="5.296" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 204.000,104.000 C 212.056,117.678 211.639,108.701 207.000,127.000" stroke-width="6.567" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 207.000,127.000 C 190.633,146.363 196.056,147.178 172.000,163.000" stroke-width="4.999" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 172.000,163.000 C 161.283,169.703 162.633,171.363 151.000,177.000" stroke-width="5.648" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 151.000,177.000 C 143.521,179.957 145.783,180.703 141.000,185.000" stroke-width="6.631" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 141.000,185.000 C 138.600,193.031 139.021,188.957 142.000,195.000" stroke-width="7.228" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 142.000,195.000 C 150.991,195.830 148.100,198.531 160.000,196.000" stroke-width="6.788" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 160.000,196.000 C 178.118,199.044 177.491,196.330 195.000,196.000" stroke-width="5.668" stroke="black" fill="none" stroke-linecap="round"></path></svg>',
    //         userId: 'c74c3a11-8399-413a-89d0-40492e8531e1'
    //       }
    //     }
    //   }
    // ]
    // },
    {
      id: "5678",
      uuid: "5678",
      account_uuid: "12341234134",
      release_status: 0,
      release_history: [
        {
          uuid: "d949b1c8-cb2e-4623-b7d1-67e68a644da8",
          release_date: "2022-09-06T03:27:44.97Z",
          released_by_member: {
            uuid: "d5cda1f0-f8b0-48ab-89cb-08a85905a289",
            email: "nicholas.tsaoucis@gravityfusion.com",
            phone: "+61431700596",
          },
          release_status: 1,
          notifications: [],
          system_note: "Shift released to client",
        },
      ],
      datetime: moment().add(1, "days").format(),
      duration: 210,
      notes: [
        {
          author_id: "5edebhh5dfis8",
          author: "Daria D",
          datetime: moment().format(),
          note: "",
        },
      ],
      changelog: [
        {
          avatar: "",
          date: moment("2021-06-18"),
          status: "created",
          user: "Selvador Kris",
        },
      ],
      venue: {
        uuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        name: "Opera Bar",
        address: {
          street_location:
            "Rua Quinta dos Morgados YY, Barreiro e Lavradio, Portugal",
          geolocation: {
            type: "Point",
            coordinates: [
              -105.7463,
              38.797222,
            ],
          },
          "google-call": "",
          "google-place-id": "",
        },
        roles: [
          {
            credentials: [
              {
                data: '{"template_uuid":"636086a9d9dbfb0537f92125","type":"Identification","name":"place accred finance","description":"Passport","timing_requirement":"Before commence the Shift","source":"https://www.example.com/licence.pdf","selected":true}',
                roles: [0],
                tags: [
                  '["tag1"',
                  '"tag2"]',
                ],
                template: "1234-1234-1234",
                type: "Induction",
                uuid: "1234-1234-1234",
              },
            ],
            resource_rate_id: "d8e28207-ad9f-4620-906c-117ef0ed4938",
            description: "Guard description",
            item_code: "#123-321",
            name: "Guard",
            rates: [
              {
                item_code: "#123 456",
                name: "Standard",
                value: 10,
              },
            ],
            uuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          },
        ],
        stations: [],
      },
      client_uuid: "56464757411",
      client_status: 0,
      shift_number: "",
      tasks: [
        {
          assessments: [],
          start_time: moment().add(1, "days").format(),
          duration: 210,
          role: {
            uuid: "e359bcee-26b4-45c2-b026-f917dd4d5672",
            name: "Guard",
            item_code: "",
            description: "guardy guard",
            rates: [
              {
                name: "Standard",
                value: 12.5,
                item_code: "3000",
              },
              {
                name: "Premium",
                value: 25.5,
                item_code: "3001",
              },
              {
                name: "Discount",
                value: 45.5,
                item_code: "3002",
              },
            ],
            credentials: [],
          },
          supervisor: false,
          supplier: {
            uuid: "7890789078907890",
            name: "Jack Allon",
            release_status: 1,
          },
          resource: {
            uuid: "123456",
            name: "Trevino Marvel3",
            release_status: 2,
          },
          notes: [
            {
              author_id: "gf34567ytydf56y",
              author: "Daria D",
              datetime: moment().format(),
              note: "",
            },
            {
              uuid: "09b8d960-c7f1-46b0-aa59-a76f5c5948c8",
              author_id: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              author: "Fred 113 Test",
              datetime: moment()
                .add(1, "hours")
                .set("minute", 30)
                .format("DD MMMM YYYY HH:mm"),
              note: "Alteration suggested by Fred 113 Test",
              hidden: false,
            },
            {
              uuid: "aeb59f12-b9b1-4b6b-8d64-308ddb382625",
              author_id: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              author: "Fred 113 Test",
              datetime: moment()
                .add(2, "hours")
                .set("minute", 30)
                .format("DD MMMM YYYY HH:mm"),
              note: "testing, shift was created ~ 2: 40 ish but the resource can be set to have started at midnight??? by Fred 113 Test",
              hidden: false,
            },
            {
              uuid: "68120443-062f-40dc-b7f9-3bcd59189fdd",
              author_id: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              author: "Fred 113 Test",
              datetime: moment()
                .add(3, "hours")
                .set("minute", 30)
                .format("DD MMMM YYYY HH:mm"),
              note: "Splits replaced by Fred 113 Test",
              hidden: false,
            },
          ],
          timesheet: {
            breaks: [
              {
                datetime: moment()
                  .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
                  .add(1, "hours"),
                duration: 3,
                geolocation: { type: "", coordinates: null },
                signature: "",
                userId: "c74c3a11-8399-413a-89d0-40492e8531e1",
              },
            ],
            signoff: {
              datetime: moment().add(5, "hours").format(),
              geolocation: { type: "", coordinates: null },
              signature:
                '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 125 80" width="125" height="80"><path d="M 80.000,109.000 C 116.028,97.120 116.500,100.000 153.000,91.000" stroke-width="6.955" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 153.000,91.000 C 212.398,76.701 188.528,85.120 225.000,85.000" stroke-width="4.286" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 225.000,85.000 C 212.279,105.493 232.898,90.201 194.000,118.000" stroke-width="5.913" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 194.000,118.000 C 160.452,128.984 174.279,131.493 149.000,137.000" stroke-width="4.890" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 149.000,137.000 C 156.510,139.459 145.452,139.484 164.000,139.000" stroke-width="6.954" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 164.000,139.000 C 180.923,135.447 181.010,137.959 198.000,134.000" stroke-width="5.769" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 198.000,134.000 C 210.500,134.000 210.423,132.947 223.000,134.000" stroke-width="7.199" stroke="black" fill="none" stroke-linecap="round"></path></svg>',
              userId: "c74c3a11-8399-413a-89d0-40492e8531e1",
            },
            signon: {
              datetime: moment().add(2, "hours").format(),
              geolocation: { type: "", coordinates: null },
              signature:
                '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 125 80" width="125" height="80"><path d="M 105.000,127.000 C 110.166,125.354 109.500,124.500 114.000,122.000" stroke-width="8.020" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 114.000,122.000 C 119.300,109.413 122.166,114.854 129.000,106.000" stroke-width="6.698" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 129.000,106.000 C 148.476,103.521 146.300,99.913 168.000,103.000" stroke-width="5.452" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 168.000,103.000 C 192.139,96.701 185.976,102.521 204.000,104.000" stroke-width="5.296" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 204.000,104.000 C 212.056,117.678 211.639,108.701 207.000,127.000" stroke-width="6.567" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 207.000,127.000 C 190.633,146.363 196.056,147.178 172.000,163.000" stroke-width="4.999" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 172.000,163.000 C 161.283,169.703 162.633,171.363 151.000,177.000" stroke-width="5.648" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 151.000,177.000 C 143.521,179.957 145.783,180.703 141.000,185.000" stroke-width="6.631" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 141.000,185.000 C 138.600,193.031 139.021,188.957 142.000,195.000" stroke-width="7.228" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 142.000,195.000 C 150.991,195.830 148.100,198.531 160.000,196.000" stroke-width="6.788" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 160.000,196.000 C 178.118,199.044 177.491,196.330 195.000,196.000" stroke-width="5.668" stroke="black" fill="none" stroke-linecap="round"></path></svg>',
              userId: "c74c3a11-8399-413a-89d0-40492e8531e1",
            },
            start: moment().add(1, "hours").set("minute", 30).format(),
            end: moment().add(2, "hours").set("minute", 30).format(),
            result: 0,
            splits: [],
            additional_times: [],
            challenges: [
              {
                user: {
                  uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
                  name: "Fred Supervisor",
                  role: "Supervisor",
                },
                signon: {
                  time: "0001-01-01T00:00:00Z",
                  accepted: false,
                },
                signoff: {
                  time: "0001-01-01T00:00:00Z",
                  accepted: false,
                },
                starttime: {
                  time: moment().add(3, "hours").set("minute", 30).format(),
                  accepted: false,
                },
                endtime: {
                  time: moment().add(4, "hours").set("minute", 30).format(),
                  accepted: false,
                },
                break: {
                  duration: 12,
                  accepted: false,
                },
              },
              {
                user: {
                  uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
                  name: "Fred Venue Manager",
                  role: "Venue Manager",
                },
                signon: {
                  time: "0001-01-01T00:00:00Z",
                  accepted: false,
                },
                signoff: {
                  time: "0001-01-01T00:00:00Z",
                  accepted: false,
                },
                starttime: {
                  time: moment().add(5, "hours").set("minute", 30).format(),
                  accepted: false,
                },
                endtime: {
                  time: moment().add(6, "hours").set("minute", 30).format(),
                  accepted: false,
                },
                break: {
                  duration: 18,
                  accepted: false,
                },
              },
            ],
          },
        },
      ],
    },
    {
      id: "9101112",
      uuid: "9101112",
      account_uuid: "12341234134",
      release_status: 1,
      release_history: [
        {
          uuid: "d949b1c8-cb2e-4623-b7d1-67e68a644da8",
          release_date: "2022-09-06T03:27:44.97Z",
          released_by_member: {
            uuid: "d5cda1f0-f8b0-48ab-89cb-08a85905a289",
            email: "nicholas.tsaoucis@gravityfusion.com",
            phone: "+61431700596",
          },
          release_status: 1,
          notifications: [],
          system_note: "Shift released to client",
        },
      ],
      datetime: moment().add(1, "days").format(),
      duration: 90,
      notes: [
        {
          author_id: "5edebhh5dfis8",
          author: "Daria D",
          datetime: moment().format(),
          note: "",
        },
      ],
      changelog: [
        {
          avatar: "",
          date: moment("2021-01-08").format(),
          status: "changed status",
          user: "Selvador Kris",
        },
        {
          date: moment("2021-07-14").format(),
          status: "created",
          user: "Jack Allon",
        },
      ],
      venue: {
        uuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        name: "Irish Pub",
        address: {
          street_location:
            "Rua Quinta dos Morgados YY, Barreiro e Lavradio, Portugal",
          geolocation: {
            type: "Point",
            coordinates: [
              -105.7463,
              38.797222,
            ],
          },
          "google-call": "",
          "google-place-id": "",
        },
        roles: [],
        stations: [],
      },
      client_uuid: "12341234134",
      client_status: 0,
      shift_number: "",
      tasks: [
        {
          assessments: [],
          start_time: moment(startOfWeek).add(2, "days").format(),
          duration: 90,
          role: {
            uuid: "e359bcee-26b4-45c2-b026-f917dd4d5672",
            name: "Guard",
            item_code: "",
            description: "guardy guard",
            rates: [
              {
                name: "Standard",
                value: 12.5,
                item_code: "3000",
              },
              {
                name: "Premium",
                value: 25.5,
                item_code: "3001",
              },
              {
                name: "Discount",
                value: 45.5,
                item_code: "3002",
              },
            ],
            credentials: [],
          },
          supervisor: false,
          supplier: {
            uuid: "7890789078907890",
            name: "Jack Allon",
            release_status: 1,
          },
          resource: {
            uuid: "123456",
            name: "Trevino Marvel4",
            release_status: 2,
          },
          notes: [
            {
              author_id: "gf34567ytydf56y",
              author: "Daria D",
              datetime: moment().format(),
              note: "",
            },
          ],
          timesheet: {
            breaks: [
              // {
              //   datetime: moment().set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).add(1, 'hours'),
              //   duration: 3,
              //   geolocation: { type: '', coordinates: null },
              //   signature: '',
              //   userId: 'c74c3a11-8399-413a-89d0-40492e8531e1'
              // }
            ],
            result: 0,
            splits: [],
            additional_times: [],
            // signoff: {
            //   datetime: moment().add(5, 'hours').format(),
            //   geolocation: { type: '', coordinates: null },
            //   signature:
            //     '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 125 80" width="125" height="80"><path d="M 80.000,109.000 C 116.028,97.120 116.500,100.000 153.000,91.000" stroke-width="6.955" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 153.000,91.000 C 212.398,76.701 188.528,85.120 225.000,85.000" stroke-width="4.286" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 225.000,85.000 C 212.279,105.493 232.898,90.201 194.000,118.000" stroke-width="5.913" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 194.000,118.000 C 160.452,128.984 174.279,131.493 149.000,137.000" stroke-width="4.890" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 149.000,137.000 C 156.510,139.459 145.452,139.484 164.000,139.000" stroke-width="6.954" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 164.000,139.000 C 180.923,135.447 181.010,137.959 198.000,134.000" stroke-width="5.769" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 198.000,134.000 C 210.500,134.000 210.423,132.947 223.000,134.000" stroke-width="7.199" stroke="black" fill="none" stroke-linecap="round"></path></svg>',
            //   userId: 'c74c3a11-8399-413a-89d0-40492e8531e1'
            // },
            // signon: {
            //   datetime: moment().add(2, 'hours').format(),
            //   geolocation: { type: '', coordinates: null },
            //   signature:
            //     '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 125 80" width="125" height="80"><path d="M 105.000,127.000 C 110.166,125.354 109.500,124.500 114.000,122.000" stroke-width="8.020" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 114.000,122.000 C 119.300,109.413 122.166,114.854 129.000,106.000" stroke-width="6.698" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 129.000,106.000 C 148.476,103.521 146.300,99.913 168.000,103.000" stroke-width="5.452" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 168.000,103.000 C 192.139,96.701 185.976,102.521 204.000,104.000" stroke-width="5.296" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 204.000,104.000 C 212.056,117.678 211.639,108.701 207.000,127.000" stroke-width="6.567" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 207.000,127.000 C 190.633,146.363 196.056,147.178 172.000,163.000" stroke-width="4.999" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 172.000,163.000 C 161.283,169.703 162.633,171.363 151.000,177.000" stroke-width="5.648" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 151.000,177.000 C 143.521,179.957 145.783,180.703 141.000,185.000" stroke-width="6.631" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 141.000,185.000 C 138.600,193.031 139.021,188.957 142.000,195.000" stroke-width="7.228" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 142.000,195.000 C 150.991,195.830 148.100,198.531 160.000,196.000" stroke-width="6.788" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 160.000,196.000 C 178.118,199.044 177.491,196.330 195.000,196.000" stroke-width="5.668" stroke="black" fill="none" stroke-linecap="round"></path></svg>',
            //   userId: 'c74c3a11-8399-413a-89d0-40492e8531e1'
            // }
          },
        },
      ],
    },
  ];

  public static shifts = [
    {
      assessments: [],
      start_time: startOfWeek.add(1, "days").format(),
      duration: 600,
      role: {
        uuid: "e359bcee-26b4-45c2-b026-f917dd4d5672",
        name: "Guard",
        item_code: "",
        description: "guardy guard",
        rates: [
          {
            name: "Standard",
            value: 12.5,
            item_code: "3000",
          },
          {
            name: "Premium",
            value: 25.5,
            item_code: "3001",
          },
          {
            name: "Discount",
            value: 45.5,
            item_code: "3002",
          },
        ],
        credentials: [],
      },
      supervisor: false,
      supplier: {
        uuid: "12341234134",
        name: "Jack Allon",
        release_status: 1,
      },
      resource: {
        uuid: "123456",
        name: "Trevino Marvel1",
        release_status: 2,
      },
      notes: [
        {
          author_id: "gf34567ytydf56y",
          author: "Daria D",
          datetime: moment().format(),
          note: "",
        },
        {
          uuid: "09b8d960-c7f1-46b0-aa59-a76f5c5948c8",
          author_id: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
          author: "Fred 113 Test",
          datetime: "21 May 2024 12:30",
          note: "Alteration suggested by Fred 113 Test",
          hidden: false,
        },
        {
          uuid: "aeb59f12-b9b1-4b6b-8d64-308ddb382625",
          author_id: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
          author: "Fred 113 Test",
          datetime: "21 May 2024 13:30",
          note: "testing, shift was created ~ 2: 40 ish but the resource can be set to have started at midnight??? by Fred 113 Test",
          hidden: false,
        },
        {
          uuid: "68120443-062f-40dc-b7f9-3bcd59189fdd",
          author_id: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
          author: "Fred 113 Test",
          datetime: "21 May 2024 14:30",
          note: "Splits replaced by Fred 113 Test",
          hidden: false,
        },
      ],
      timesheet: {
        breaks: [
          {
            datetime: moment()
              .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
              .add(1, "hours"),
            duration: 6,
            geolocation: { type: "", coordinates: null },
            signature: "",
            userId: "c74c3a11-8399-413a-89d0-40492e8531e1",
          },
        ],
        signoff: {
          datetime: moment().add(5, "hours").format(),
          geolocation: { type: "", coordinates: null },
          signature:
            '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 125 80" width="125" height="80"><path d="M 80.000,109.000 C 116.028,97.120 116.500,100.000 153.000,91.000" stroke-width="6.955" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 153.000,91.000 C 212.398,76.701 188.528,85.120 225.000,85.000" stroke-width="4.286" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 225.000,85.000 C 212.279,105.493 232.898,90.201 194.000,118.000" stroke-width="5.913" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 194.000,118.000 C 160.452,128.984 174.279,131.493 149.000,137.000" stroke-width="4.890" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 149.000,137.000 C 156.510,139.459 145.452,139.484 164.000,139.000" stroke-width="6.954" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 164.000,139.000 C 180.923,135.447 181.010,137.959 198.000,134.000" stroke-width="5.769" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 198.000,134.000 C 210.500,134.000 210.423,132.947 223.000,134.000" stroke-width="7.199" stroke="black" fill="none" stroke-linecap="round"></path></svg>',
          userId: "c74c3a11-8399-413a-89d0-40492e8531e1",
        },
        signon: {
          datetime: moment().add(2, "hours").format(),
          geolocation: { type: "", coordinates: null },
          signature:
            '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 125 80" width="125" height="80"><path d="M 105.000,127.000 C 110.166,125.354 109.500,124.500 114.000,122.000" stroke-width="8.020" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 114.000,122.000 C 119.300,109.413 122.166,114.854 129.000,106.000" stroke-width="6.698" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 129.000,106.000 C 148.476,103.521 146.300,99.913 168.000,103.000" stroke-width="5.452" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 168.000,103.000 C 192.139,96.701 185.976,102.521 204.000,104.000" stroke-width="5.296" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 204.000,104.000 C 212.056,117.678 211.639,108.701 207.000,127.000" stroke-width="6.567" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 207.000,127.000 C 190.633,146.363 196.056,147.178 172.000,163.000" stroke-width="4.999" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 172.000,163.000 C 161.283,169.703 162.633,171.363 151.000,177.000" stroke-width="5.648" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 151.000,177.000 C 143.521,179.957 145.783,180.703 141.000,185.000" stroke-width="6.631" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 141.000,185.000 C 138.600,193.031 139.021,188.957 142.000,195.000" stroke-width="7.228" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 142.000,195.000 C 150.991,195.830 148.100,198.531 160.000,196.000" stroke-width="6.788" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 160.000,196.000 C 178.118,199.044 177.491,196.330 195.000,196.000" stroke-width="5.668" stroke="black" fill="none" stroke-linecap="round"></path></svg>',
          userId: "c74c3a11-8399-413a-89d0-40492e8531e1",
        },
        result: 0,
        splits: [],
        additional_times: [],
        start: moment().add(1, "hours").set("minute", 30).format(),
        end: moment().add(2, "hours").set("minute", 30).format(),
        challenges: [
          {
            user: {
              uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              name: "Fred Supervisor",
              role: "Supervisor",
            },
            signon: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            signoff: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            starttime: {
              time: moment().add(3, "hours").set("minute", 30).format(),
              accepted: false,
            },
            endtime: {
              time: moment().add(4, "hours").set("minute", 30).format(),
              accepted: false,
            },
            break: {
              duration: 12,
              accepted: false,
            },
          },
          {
            user: {
              uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              name: "Fred Venue Manager",
              role: "Venue Manager",
            },
            signon: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            signoff: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            starttime: {
              time: moment().add(5, "hours").set("minute", 30).format(),
              accepted: false,
            },
            endtime: {
              time: moment().add(6, "hours").set("minute", 30).format(),
              accepted: false,
            },
            break: {
              duration: 18,
              accepted: false,
            },
          },
          {
            user: {
              uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              name: "Fred Supervisor1",
              role: "Supervisor",
            },
            signon: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            signoff: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            starttime: {
              time: moment().add(3, "hours").set("minute", 31).format(),
              accepted: false,
            },
            endtime: {
              time: moment().add(4, "hours").set("minute", 31).format(),
              accepted: false,
            },
            break: {
              duration: 12,
              accepted: false,
            },
          },
          {
            user: {
              uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              name: "Fred Venue Manager1",
              role: "Venue Manager",
            },
            signon: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            signoff: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            starttime: {
              time: moment().add(5, "hours").set("minute", 32).format(),
              accepted: false,
            },
            endtime: {
              time: moment().add(6, "hours").set("minute", 32).format(),
              accepted: false,
            },
            break: {
              duration: 18,
              accepted: false,
            },
          },
          {
            user: {
              uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              name: "Fred Supervisor2",
              role: "Supervisor",
            },
            signon: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            signoff: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            starttime: {
              time: moment().add(3, "hours").set("minute", 33).format(),
              accepted: false,
            },
            endtime: {
              time: moment().add(4, "hours").set("minute", 33).format(),
              accepted: false,
            },
            break: {
              duration: 12,
              accepted: false,
            },
          },
          {
            user: {
              uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              name: "Fred Venue Manager2",
              role: "Venue Manager",
            },
            signon: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            signoff: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            starttime: {
              time: moment().add(5, "hours").set("minute", 34).format(),
              accepted: false,
            },
            endtime: {
              time: moment().add(6, "hours").set("minute", 34).format(),
              accepted: false,
            },
            break: {
              duration: 18,
              accepted: false,
            },
          },
        ],
      },
    },
    {
      assessments: [],
      start_time: moment(startOfWeek).add(2, "days").format(),
      duration: 300,
      role: {
        uuid: "e359bcee-26b4-45c2-b026-f917dd4d5672",
        name: "Guard",
        item_code: "",
        description: "guardy guard",
        rates: [
          {
            name: "Standard",
            value: 12.5,
            item_code: "3000",
          },
          {
            name: "Premium",
            value: 25.5,
            item_code: "3001",
          },
          {
            name: "Discount",
            value: 45.5,
            item_code: "3002",
          },
        ],
        credentials: [],
      },
      supervisor: false,
      supplier: {
        uuid: "12341234134",
        name: "Jack Allon",
        release_status: 1,
      },
      resource: {
        uuid: "123456",
        name: "Trevino Marvel1",
        release_status: 2,
      },
      notes: [
        {
          author_id: "gf34567ytydf56y",
          author: "Daria D",
          datetime: moment().format(),
          note: "",
        },
        {
          uuid: "09b8d960-c7f1-46b0-aa59-a76f5c5948c8",
          author_id: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
          author: "Fred 113 Test",
          datetime: "21 May 2024 12:30",
          note: "Alteration suggested by Fred 113 Test",
          hidden: false,
        },
        {
          uuid: "aeb59f12-b9b1-4b6b-8d64-308ddb382625",
          author_id: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
          author: "Fred 113 Test",
          datetime: "21 May 2024 13:30",
          note: "testing, shift was created ~ 2: 40 ish but the resource can be set to have started at midnight??? by Fred 113 Test",
          hidden: false,
        },
        {
          uuid: "68120443-062f-40dc-b7f9-3bcd59189fdd",
          author_id: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
          author: "Fred 113 Test",
          datetime: "21 May 2024 14:30",
          note: "Splits replaced by Fred 113 Test",
          hidden: false,
        },
      ],
      timesheet: {
        breaks: [
          {
            datetime: moment()
              .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
              .add(1, "hours"),
            duration: 6,
            geolocation: { type: "", coordinates: null },
            signature: "",
            userId: "c74c3a11-8399-413a-89d0-40492e8531e1",
          },
        ],
        signoff: {
          datetime: moment().add(5, "hours").format(),
          geolocation: { type: "", coordinates: null },
          signature:
            '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 125 80" width="125" height="80"><path d="M 80.000,109.000 C 116.028,97.120 116.500,100.000 153.000,91.000" stroke-width="6.955" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 153.000,91.000 C 212.398,76.701 188.528,85.120 225.000,85.000" stroke-width="4.286" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 225.000,85.000 C 212.279,105.493 232.898,90.201 194.000,118.000" stroke-width="5.913" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 194.000,118.000 C 160.452,128.984 174.279,131.493 149.000,137.000" stroke-width="4.890" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 149.000,137.000 C 156.510,139.459 145.452,139.484 164.000,139.000" stroke-width="6.954" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 164.000,139.000 C 180.923,135.447 181.010,137.959 198.000,134.000" stroke-width="5.769" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 198.000,134.000 C 210.500,134.000 210.423,132.947 223.000,134.000" stroke-width="7.199" stroke="black" fill="none" stroke-linecap="round"></path></svg>',
          userId: "c74c3a11-8399-413a-89d0-40492e8531e1",
        },
        signon: {
          datetime: moment().add(2, "hours").format(),
          geolocation: { type: "", coordinates: null },
          signature:
            '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 125 80" width="125" height="80"><path d="M 105.000,127.000 C 110.166,125.354 109.500,124.500 114.000,122.000" stroke-width="8.020" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 114.000,122.000 C 119.300,109.413 122.166,114.854 129.000,106.000" stroke-width="6.698" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 129.000,106.000 C 148.476,103.521 146.300,99.913 168.000,103.000" stroke-width="5.452" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 168.000,103.000 C 192.139,96.701 185.976,102.521 204.000,104.000" stroke-width="5.296" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 204.000,104.000 C 212.056,117.678 211.639,108.701 207.000,127.000" stroke-width="6.567" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 207.000,127.000 C 190.633,146.363 196.056,147.178 172.000,163.000" stroke-width="4.999" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 172.000,163.000 C 161.283,169.703 162.633,171.363 151.000,177.000" stroke-width="5.648" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 151.000,177.000 C 143.521,179.957 145.783,180.703 141.000,185.000" stroke-width="6.631" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 141.000,185.000 C 138.600,193.031 139.021,188.957 142.000,195.000" stroke-width="7.228" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 142.000,195.000 C 150.991,195.830 148.100,198.531 160.000,196.000" stroke-width="6.788" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 160.000,196.000 C 178.118,199.044 177.491,196.330 195.000,196.000" stroke-width="5.668" stroke="black" fill="none" stroke-linecap="round"></path></svg>',
          userId: "c74c3a11-8399-413a-89d0-40492e8531e1",
        },
        result: 0,
        splits: [],
        additional_times: [],
        start: moment().add(1, "hours").set("minute", 30).format(),
        end: moment().add(2, "hours").set("minute", 30).format(),
        challenges: [
          {
            user: {
              uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              name: "Fred Supervisor",
              role: "Supervisor",
            },
            signon: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            signoff: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            starttime: {
              time: moment().add(3, "hours").set("minute", 30).format(),
              accepted: false,
            },
            endtime: {
              time: moment().add(4, "hours").set("minute", 30).format(),
              accepted: false,
            },
            break: {
              duration: 12,
              accepted: false,
            },
          },
          {
            user: {
              uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              name: "Fred Venue Manager",
              role: "Venue Manager",
            },
            signon: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            signoff: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            starttime: {
              time: moment().add(5, "hours").set("minute", 30).format(),
              accepted: false,
            },
            endtime: {
              time: moment().add(6, "hours").set("minute", 30).format(),
              accepted: false,
            },
            break: {
              duration: 18,
              accepted: false,
            },
          },
          {
            user: {
              uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              name: "Fred Supervisor1",
              role: "Supervisor",
            },
            signon: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            signoff: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            starttime: {
              time: moment().add(3, "hours").set("minute", 31).format(),
              accepted: false,
            },
            endtime: {
              time: moment().add(4, "hours").set("minute", 31).format(),
              accepted: false,
            },
            break: {
              duration: 12,
              accepted: false,
            },
          },
          {
            user: {
              uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              name: "Fred Venue Manager1",
              role: "Venue Manager",
            },
            signon: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            signoff: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            starttime: {
              time: moment().add(5, "hours").set("minute", 32).format(),
              accepted: false,
            },
            endtime: {
              time: moment().add(6, "hours").set("minute", 32).format(),
              accepted: false,
            },
            break: {
              duration: 18,
              accepted: false,
            },
          },
          {
            user: {
              uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              name: "Fred Supervisor2",
              role: "Supervisor",
            },
            signon: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            signoff: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            starttime: {
              time: moment().add(3, "hours").set("minute", 33).format(),
              accepted: false,
            },
            endtime: {
              time: moment().add(4, "hours").set("minute", 33).format(),
              accepted: false,
            },
            break: {
              duration: 12,
              accepted: false,
            },
          },
          {
            user: {
              uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              name: "Fred Venue Manager2",
              role: "Venue Manager",
            },
            signon: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            signoff: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            starttime: {
              time: moment().add(5, "hours").set("minute", 34).format(),
              accepted: false,
            },
            endtime: {
              time: moment().add(6, "hours").set("minute", 34).format(),
              accepted: false,
            },
            break: {
              duration: 18,
              accepted: false,
            },
          },
        ],
      },
    },
    {
      assessments: [],
      start_time: startOfWeek.add(3, "days").format(),
      duration: 250,
      role: {
        uuid: "e359bcee-26b4-45c2-b026-f917dd4d5672",
        name: "Guard",
        item_code: "",
        description: "guardy guard",
        rates: [
          {
            name: "Standard",
            value: 12.5,
            item_code: "3000",
          },
          {
            name: "Premium",
            value: 25.5,
            item_code: "3001",
          },
          {
            name: "Discount",
            value: 45.5,
            item_code: "3002",
          },
        ],
        credentials: [],
      },
      supervisor: false,
      supplier: {
        uuid: "12341234134",
        name: "Jack Allon",
        release_status: 1,
      },
      resource: {
        uuid: "123456",
        name: "Trevino Marvel1",
        release_status: 2,
      },
      notes: [
        {
          author_id: "gf34567ytydf56y",
          author: "Daria D",
          datetime: moment().format(),
          note: "",
        },
        {
          uuid: "09b8d960-c7f1-46b0-aa59-a76f5c5948c8",
          author_id: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
          author: "Fred 113 Test",
          datetime: "21 May 2024 12:30",
          note: "Alteration suggested by Fred 113 Test",
          hidden: false,
        },
        {
          uuid: "aeb59f12-b9b1-4b6b-8d64-308ddb382625",
          author_id: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
          author: "Fred 113 Test",
          datetime: "21 May 2024 13:30",
          note: "testing, shift was created ~ 2: 40 ish but the resource can be set to have started at midnight??? by Fred 113 Test",
          hidden: false,
        },
        {
          uuid: "68120443-062f-40dc-b7f9-3bcd59189fdd",
          author_id: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
          author: "Fred 113 Test",
          datetime: "21 May 2024 14:30",
          note: "Splits replaced by Fred 113 Test",
          hidden: false,
        },
      ],
      timesheet: {
        breaks: [
          {
            datetime: moment()
              .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
              .add(1, "hours"),
            duration: 6,
            geolocation: { type: "", coordinates: null },
            signature: "",
            userId: "c74c3a11-8399-413a-89d0-40492e8531e1",
          },
        ],
        signoff: {
          datetime: moment().add(5, "hours").format(),
          geolocation: { type: "", coordinates: null },
          signature:
            '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 125 80" width="125" height="80"><path d="M 80.000,109.000 C 116.028,97.120 116.500,100.000 153.000,91.000" stroke-width="6.955" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 153.000,91.000 C 212.398,76.701 188.528,85.120 225.000,85.000" stroke-width="4.286" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 225.000,85.000 C 212.279,105.493 232.898,90.201 194.000,118.000" stroke-width="5.913" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 194.000,118.000 C 160.452,128.984 174.279,131.493 149.000,137.000" stroke-width="4.890" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 149.000,137.000 C 156.510,139.459 145.452,139.484 164.000,139.000" stroke-width="6.954" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 164.000,139.000 C 180.923,135.447 181.010,137.959 198.000,134.000" stroke-width="5.769" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 198.000,134.000 C 210.500,134.000 210.423,132.947 223.000,134.000" stroke-width="7.199" stroke="black" fill="none" stroke-linecap="round"></path></svg>',
          userId: "c74c3a11-8399-413a-89d0-40492e8531e1",
        },
        signon: {
          datetime: moment().add(2, "hours").format(),
          geolocation: { type: "", coordinates: null },
          signature:
            '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 125 80" width="125" height="80"><path d="M 105.000,127.000 C 110.166,125.354 109.500,124.500 114.000,122.000" stroke-width="8.020" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 114.000,122.000 C 119.300,109.413 122.166,114.854 129.000,106.000" stroke-width="6.698" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 129.000,106.000 C 148.476,103.521 146.300,99.913 168.000,103.000" stroke-width="5.452" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 168.000,103.000 C 192.139,96.701 185.976,102.521 204.000,104.000" stroke-width="5.296" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 204.000,104.000 C 212.056,117.678 211.639,108.701 207.000,127.000" stroke-width="6.567" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 207.000,127.000 C 190.633,146.363 196.056,147.178 172.000,163.000" stroke-width="4.999" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 172.000,163.000 C 161.283,169.703 162.633,171.363 151.000,177.000" stroke-width="5.648" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 151.000,177.000 C 143.521,179.957 145.783,180.703 141.000,185.000" stroke-width="6.631" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 141.000,185.000 C 138.600,193.031 139.021,188.957 142.000,195.000" stroke-width="7.228" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 142.000,195.000 C 150.991,195.830 148.100,198.531 160.000,196.000" stroke-width="6.788" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 160.000,196.000 C 178.118,199.044 177.491,196.330 195.000,196.000" stroke-width="5.668" stroke="black" fill="none" stroke-linecap="round"></path></svg>',
          userId: "c74c3a11-8399-413a-89d0-40492e8531e1",
        },
        result: 0,
        splits: [],
        additional_times: [],
        start: moment().add(1, "hours").set("minute", 30).format(),
        end: moment().add(2, "hours").set("minute", 30).format(),
        challenges: [
          {
            user: {
              uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              name: "Fred Supervisor",
              role: "Supervisor",
            },
            signon: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            signoff: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            starttime: {
              time: moment().add(3, "hours").set("minute", 30).format(),
              accepted: false,
            },
            endtime: {
              time: moment().add(4, "hours").set("minute", 30).format(),
              accepted: false,
            },
            break: {
              duration: 12,
              accepted: false,
            },
          },
          {
            user: {
              uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              name: "Fred Venue Manager",
              role: "Venue Manager",
            },
            signon: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            signoff: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            starttime: {
              time: moment().add(5, "hours").set("minute", 30).format(),
              accepted: false,
            },
            endtime: {
              time: moment().add(6, "hours").set("minute", 30).format(),
              accepted: false,
            },
            break: {
              duration: 18,
              accepted: false,
            },
          },
          {
            user: {
              uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              name: "Fred Supervisor1",
              role: "Supervisor",
            },
            signon: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            signoff: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            starttime: {
              time: moment().add(3, "hours").set("minute", 31).format(),
              accepted: false,
            },
            endtime: {
              time: moment().add(4, "hours").set("minute", 31).format(),
              accepted: false,
            },
            break: {
              duration: 12,
              accepted: false,
            },
          },
          {
            user: {
              uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              name: "Fred Venue Manager1",
              role: "Venue Manager",
            },
            signon: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            signoff: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            starttime: {
              time: moment().add(5, "hours").set("minute", 32).format(),
              accepted: false,
            },
            endtime: {
              time: moment().add(6, "hours").set("minute", 32).format(),
              accepted: false,
            },
            break: {
              duration: 18,
              accepted: false,
            },
          },
          {
            user: {
              uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              name: "Fred Supervisor2",
              role: "Supervisor",
            },
            signon: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            signoff: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            starttime: {
              time: moment().add(3, "hours").set("minute", 33).format(),
              accepted: false,
            },
            endtime: {
              time: moment().add(4, "hours").set("minute", 33).format(),
              accepted: false,
            },
            break: {
              duration: 12,
              accepted: false,
            },
          },
          {
            user: {
              uuid: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
              name: "Fred Venue Manager2",
              role: "Venue Manager",
            },
            signon: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            signoff: {
              time: "0001-01-01T00:00:00Z",
              accepted: false,
            },
            starttime: {
              time: moment().add(5, "hours").set("minute", 34).format(),
              accepted: false,
            },
            endtime: {
              time: moment().add(6, "hours").set("minute", 34).format(),
              accepted: false,
            },
            break: {
              duration: 18,
              accepted: false,
            },
          },
        ],
      },
    },
  ];

  public static venueTasks = [
    // {
    //   assessments: [],
    //   start_time: moment().subtract(1, "days").format(),
    //   duration: 600,
    //   role: {
    //     uuid: "e359bcee-26b4-45c2-b026-f917dd4d5672",
    //     name: "Guard",
    //     item_code: "",
    //     description: "guardy guard",
    //     rates: [
    //       {
    //         name: "Standard",
    //         value: 12.5,
    //         item_code: "3000",
    //       },
    //       {
    //         name: "Premium",
    //         value: 25.5,
    //         item_code: "3001",
    //       },
    //       {
    //         name: "Discount",
    //         value: 45.5,
    //         item_code: "3002",
    //       },
    //     ],
    //     credentials: [],
    //   },
    //   supervisor: false,
    //   supplier: {
    //     uuid: "789078",
    //     name: "Jack Allon",
    //     release_status: 1,
    //   },
    //   resource: {
    //     uuid: "123456",
    //     name: "Trevino Marvel",
    //     release_status: 2,
    //   },
    //   notes: [
    //     {
    //       author_id: "gf34567ytydf56y",
    //       author: "Daria D",
    //       datetime: moment().format(),
    //       note: "",
    //     },
    //   ],
    //   timesheet: {
    //     breaks: [
    //       // {
    //       //   datetime: moment().set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).add(1, 'hours'),
    //       //   duration: 3,
    //       //   geolocation: { type: '', coordinates: null },
    //       //   signature: '',
    //       //   userId: 'c74c3a11-8399-413a-89d0-40492e8531e1'
    //       // }
    //     ],
    //     result: 0,
    //     splits: [],
    //     additional_times: [],
    //     // signoff: {
    //     //   datetime: moment().add(5, 'hours').format(),
    //     //   geolocation: { type: '', coordinates: null },
    //     //   signature:
    //     //     '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 125 80" width="125" height="80"><path d="M 80.000,109.000 C 116.028,97.120 116.500,100.000 153.000,91.000" stroke-width="6.955" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 153.000,91.000 C 212.398,76.701 188.528,85.120 225.000,85.000" stroke-width="4.286" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 225.000,85.000 C 212.279,105.493 232.898,90.201 194.000,118.000" stroke-width="5.913" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 194.000,118.000 C 160.452,128.984 174.279,131.493 149.000,137.000" stroke-width="4.890" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 149.000,137.000 C 156.510,139.459 145.452,139.484 164.000,139.000" stroke-width="6.954" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 164.000,139.000 C 180.923,135.447 181.010,137.959 198.000,134.000" stroke-width="5.769" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 198.000,134.000 C 210.500,134.000 210.423,132.947 223.000,134.000" stroke-width="7.199" stroke="black" fill="none" stroke-linecap="round"></path></svg>',
    //     //   userId: 'c74c3a11-8399-413a-89d0-40492e8531e1'
    //     // },
    //     // signon: {
    //     //   datetime: moment().add(2, 'hours').format(),
    //     //   geolocation: { type: '', coordinates: null },
    //     //   signature:
    //     //     '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 125 80" width="125" height="80"><path d="M 105.000,127.000 C 110.166,125.354 109.500,124.500 114.000,122.000" stroke-width="8.020" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 114.000,122.000 C 119.300,109.413 122.166,114.854 129.000,106.000" stroke-width="6.698" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 129.000,106.000 C 148.476,103.521 146.300,99.913 168.000,103.000" stroke-width="5.452" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 168.000,103.000 C 192.139,96.701 185.976,102.521 204.000,104.000" stroke-width="5.296" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 204.000,104.000 C 212.056,117.678 211.639,108.701 207.000,127.000" stroke-width="6.567" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 207.000,127.000 C 190.633,146.363 196.056,147.178 172.000,163.000" stroke-width="4.999" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 172.000,163.000 C 161.283,169.703 162.633,171.363 151.000,177.000" stroke-width="5.648" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 151.000,177.000 C 143.521,179.957 145.783,180.703 141.000,185.000" stroke-width="6.631" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 141.000,185.000 C 138.600,193.031 139.021,188.957 142.000,195.000" stroke-width="7.228" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 142.000,195.000 C 150.991,195.830 148.100,198.531 160.000,196.000" stroke-width="6.788" stroke="black" fill="none" stroke-linecap="round"></path><path d="M 160.000,196.000 C 178.118,199.044 177.491,196.330 195.000,196.000" stroke-width="5.668" stroke="black" fill="none" stroke-linecap="round"></path></svg>',
    //     //   userId: 'c74c3a11-8399-413a-89d0-40492e8531e1'
    //     // }
    //   },
    // },
    {
      uuid: "bd526261-9419-4a05-9dca-08ee8ef0bb16",
      notes: [
        {
          uuid: "046b72d9-1c69-437d-a863-920c322682d3",
          author_id: "51c47f6e-b34c-4084-a011-4fd4a274bd19",
          author: "",
          datetime: "2024-11-23T04:49:50.661Z",
          note: "",
          hidden: false,
        },
      ],
      task_items: [],
      supervisor: false,
      supplier: {
        uuid: "789078",
        release_status: 2,
        name: "ROBERTS & BENBOW FAMILY TRUST",
        email: "",
        logo: "https://devdoc.gravityfusion.com.au/logo/4a799a2a-1701-47b0-b136-ddd97469e5f8.png?1679960596812",
        aggregate_assessment: {
          _id: "",
          total: 0,
          avg: 0,
          "1Star": 0,
          "2Star": 0,
          "3Star": 0,
          "4Star": 0,
          "5Star": 0,
        },
      },
      resource: {
        uuid: "123456",
        release_status: 2,
        name: "Trevino Marvel",
        email: "ft+100@mail.com",
        logo: "e4d57ed7-087a-4a73-ace2-02f043f42f83",
        aggregate_assessment: {
          _id: "",
          total: 0,
          avg: 0,
          "1Star": 0,
          "2Star": 0,
          "3Star": 0,
          "4Star": 0,
          "5Star": 0,
        },
      },
      timesheet: {
        uuid: "b2c077e3-97f3-4025-a753-2bda0c54c421",
        signon: {
          uuid: "",
          datetime: "0001-01-01T00:00:00Z",
          geolocation: {
            type: "",
            coordinates: null,
          },
          role: 0,
        },
        signoff: {
          uuid: "",
          datetime: "0001-01-01T00:00:00Z",
          geolocation: {
            type: "",
            coordinates: null,
          },
          role: 0,
        },
        breaks: [],
        challenges: [],
        challenges_confirmed: false,
        additional_times: [],
        splits: [],
        result: 0,
        start: moment().add(5, "minutes").format(),
        end: moment().add(5, "hours").format(),
      },
      shift_number: "",
      shift_uuid: "1289",
      account_uuid: "4a799a2a-1701-47b0-b136-ddd97469e5f8",
      // start_time: "2024-11-24T06:00:00Z",
      start_time: moment().format(),
      duration: 240,
      admins: null,
      supervisor_assessment: {
        uuid: "",
        assessor: "",
        datetime: "0001-01-01T00:00:00Z",
        rating: 0,
        comment: "",
        assessor_type: "",
      },
      venue_manager_assessment: {
        uuid: "",
        assessor: "",
        datetime: "0001-01-01T00:00:00Z",
        rating: 0,
        comment: "",
        assessor_type: "",
      },
      role: {
        uuid: "e359bcee-26b4-45c2-b026-f917dd4d5672",
        name: "Basic Role",
        item_code: "22",
        description: "desc",
        rates: [
          {
            name: "Standard",
            value: 12.5,
            item_code: "3000",
          },
          {
            name: "Premium",
            value: 25.5,
            item_code: "3001",
          },
          {
            name: "Discount",
            value: 45.5,
            item_code: "3002",
          },
        ],
        credentials: [],
      },
      form_data: [],
      reconciled: false,
      resource_snapshot_credentials: [],
      until_required: true,
      revised_times: [],
      venue_uuid: "",
    },
  ];
}
